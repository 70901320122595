import React from "react";
import { SignIn as AmplifySignIn } from "aws-amplify-react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import bike from "../assets/images/bike.png";
import BtnLink from "../common/BtnLink";
import AppTitle from "../common/AppTitle";
import Translate from "../common/Translate";
import { initLanguage } from "../common/language";
import { signOutUsers, getUserStatus } from "../common/cognito-services";
import CircularProgress from "@material-ui/core/CircularProgress";
import { theme } from "../theme";
import { AppContext } from "../AppProvider";
import ErrorToast from "../common/ErrorToast";
import { Auth } from "aws-amplify";
import { config } from "../config";

const useStyles = (themeSecond) => ({
  root: {
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important",
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)",
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500",
    },
  },
  image: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.8)),url(${bike})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    // margin: themeSecond.spacing(8, 4),
    margin: "25px",
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    padding: themeSecond.spacing(9),
    position: "relative",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: themeSecond.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff",
    },
  },
  submit: {
    margin: themeSecond.spacing(6, 0, 1),
    fontSize: "24px",
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    marginTop: "8px",
  },
  createAcc: {
    color: "#fa667c",
  },
  bgStyle: {
    background: "#181C28 !important",
    padding: "100px",
  },
});

export class SignIn extends AmplifySignIn {
  constructor(props) {
    super(props);
    initLanguage();
    this.state = {
      username: "",
      password: "",
      error: "",
      loading: false,
    };
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      this.login(this.state.username, this.state.password);
    }
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.authState !== prevProps.authState &&
      this.props.authState === "signIn"
    ) {
      this.setState({ error: "" });
    }
  }

  login = (username, password) => {
    this.setState({ loading: true });
    Auth.signIn({
      username,
      password,
    })
      .then((cognitoUser) => {
        if (cognitoUser.challengeName === undefined) {
          Auth.currentUserCredentials().then((res) => {
            var credentials = {
              region: config.cognito.backOfficeUserPool.region,
              credentials: Auth.essentialCredentials(res),
            };
            getUserStatus(username, credentials).then((status) => {
              if (status === "CONFIRMED") {
                signOutUsers(
                  cognitoUser.signInUserSession.accessToken.jwtToken,
                  credentials
                )
                  .then((res) => {
                    Auth.signIn({
                      username,
                      password,
                    }).then((user) => {
                      this.setState({ loading: false });
                      this.props.onStateChange("signedIn");
                    });
                  })
                  .catch((err) => {
                    this.setState({ loading: false });
                    this.setState({ error: err || err.log });
                  });
              }
            });
          });
        } else if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.signIn();
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.setState({ error: err || err.log });
      });
  };

  showComponent() {
    const { classes } = this.props;
    return (
      <AppContext.Consumer>
        {(context) => (
          <Grid container component="main" className={classes.root}>
            <ErrorToast error={this.state.error} />
            <CssBaseline />
            <Grid item xs={false} sm={4} md={6} className={classes.image} />
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              component={Paper}
              elevation={6}
              square
              className={classes.bgStyle}
            >
              {/*to do : wrap the app into MuiThemeProvider*/}
              <MuiThemeProvider theme={theme}>
                <AppTitle big />
                <div className={classes.paper}>
                  <Typography variant="h1">
                    <Translate i18nKey="login_to_your_account" />
                  </Typography>
                  <form className={classes.form} /*onSubmit={this.signIn}*/>
                    <TextField
                      fullWidth
                      margin="normal"
                      required
                      id="username"
                      key="username"
                      name="username"
                      onChange={(e) => {
                        this.handleInputChange(e);
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                      onKeyPress={this.handleKeyPress}
                      label={<Translate i18nKey="username" />}
                      autoComplete="username"
                      autoFocus
                    />
                    <TextField
                      fullWidth
                      className={classes.textField}
                      margin="normal"
                      required
                      label={<Translate i18nKey="password" />}
                      type="password"
                      id="password"
                      key="password"
                      name="password"
                      onChange={(e) => {
                        this.handleInputChange(e);
                        this.setState({ [e.target.name]: e.target.value });
                      }}
                      onKeyPress={this.handleKeyPress}
                      autoComplete="current-password"
                    />
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        style={{ display: "inline-block" }}
                      >
                        <Translate i18nKey="forgot_your_password?" />
                      </Typography>
                      <BtnLink
                        click={() => this.changeState("forgotPassword")}
                        label={<Translate i18nKey="reset_password" />}
                      />
                    </Grid>
                    <Button
                      //type="submit"
                      onClick={() =>
                        this.login(this.state.username, this.state.password)
                      }
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      {/* the state is set by the original Amplify SingIn component, it is only created once the user clicks on the signin button */}
                      {this.state.loading ? (
                        <CircularProgress
                          style={{ color: theme.palette.common.white }}
                        />
                      ) : (
                        <Translate i18nKey="sign_in" />
                      )}
                    </Button>
                  </form>
                </div>
              </MuiThemeProvider>
            </Grid>
          </Grid>
        )}
      </AppContext.Consumer>
    );
  }
}

export default withStyles(useStyles)(SignIn);
