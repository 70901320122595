import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import DescriptionIcon from "@material-ui/icons/Description";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import GroupIcon from "@material-ui/icons/Group";
import EventIcon from "@material-ui/icons/Event";
import PermDataSettingIcon from "@material-ui/icons/PermDataSetting";
import ScheduleIcon from "@material-ui/icons/Schedule";
import MotorcycleRoundedIcon from "@material-ui/icons/MotorcycleRounded";
import { makeStyles } from "@material-ui/styles";
import React, { Fragment /*, useEffect */} from "react";
import { NavLink } from "react-router-dom";
import Translate from "./Translate";
import { useState } from "react";
import MemoryIcon from "@material-ui/icons/Memory";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import LabelIcon from "@material-ui/icons/Label";
import FlagIcon from "@material-ui/icons/Flag";
import AvTimerIcon from "@material-ui/icons/AvTimer";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import ExposureIcon from "@material-ui/icons/Exposure";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags";
import ViewQuiltIcon from "@material-ui/icons/ViewQuilt";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import FunctionsIcon from "@material-ui/icons/Functions";
import ListIcon from "@material-ui/icons/List";
import ScreenLockLandscapeIcon from "@material-ui/icons/ScreenLockLandscape";
import WebIcon from "@material-ui/icons/Web";
import BackspaceIcon from "@material-ui/icons/Backspace";
import WarningIcon from "@material-ui/icons/Warning";
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ListAltIcon from "@material-ui/icons/ListAlt";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PostAddIcon from '@material-ui/icons/PostAdd';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  icons: {
    fill: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.text.secondary,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 1,
    paddingBottom: 1,
    backgroundColor: theme.palette.primary.main + 15,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      "& *": {
        color: "#fff",
      },
    },
  },
  active: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& *": {
      color: "#fff",
    },
  },
}));

function CustomChildItem({ styling, nested, icon, label, route, styleRoutes }) {
  const c = useStyles();
  const locationPath =  window.location.pathname.includes("/paddock/details") ? "/paddock/details/:id1/:id2" : (window.location.pathname.includes("/event/") ? "/event/:id" : window.location.pathname);
  return (
    <ListItem
      style={styling}
      button
      component={NavLink}
      to={{ pathname: route, search: window.location.search }}
      className={`${styleRoutes.includes(locationPath) && c.active} ${
        nested && c.nested
      }`}
    >
      <ListItemIcon className={c.icons}>{icon}</ListItemIcon>
      <ListItemText className={c.text} primary={label} />
    </ListItem>
  );
}

function CustomParentItem({ styling, label, icon, onClick, open, list }) {
  const c = useStyles();
  return (
    <Fragment>
      <ListItem button onClick={onClick} style={styling}>
        <ListItemIcon className={c.icons}>{icon}</ListItemIcon>
        <ListItemText className={c.text} primary={label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {list}
      </Collapse>
    </Fragment>
  );
}
var style = {
  zIndex: 9999,
};

export default function MainMenu({ context, className }) {
  const [openRole, setOpenRole] = useState(false);
  const [openControl, setOpenControl] = useState(false);
  const [openManual, setOpenManual] = useState(false);

  const [openTrackEvent, setOpenTrackEvent] = useState(false);

  const [openEventLog, setOpenEventLog] = useState(false);
  const [openEventDashboard, setOpenEventDashboard] = useState(false);

  const [openMachine, setOpenMachine] = useState(false);
  const [openCompetManagement, setOpenCompetManagement] = useState(false);

   // Automatic sign out *****************************************************************
  // useEffect(() => {
  //   Auth.currentUserCredentials().catch((error) => {
  //     console.log('The error:', error);
  //     Auth.signOut();
  //   });
  // }, []);
  // **************************************************************************************

  const handleRoleClick = () => setOpenRole(!openRole);
  const handleControlClick = () => setOpenControl(!openControl);
  const handleManual = () => setOpenManual(!openManual);

  const handleOnTrackEventClick = () => setOpenTrackEvent(!openTrackEvent);

  const handleEventLogClick = () => setOpenEventLog(!openEventLog);
  const handleEventDashboardClick = () => setOpenEventDashboard(!openEventDashboard);

  const handleMachineClick = () => setOpenMachine(!openMachine);
  const handleCompetManagementClick = () =>
    setOpenCompetManagement(!openCompetManagement);

  return (
    <List className={className} style={style}>
      {context.accessible("race_management") && (
        <CustomParentItem
          open={openRole}
          onClick={handleRoleClick}
          label={<Translate i18nKey="race_management" />}
          icon={<MotorcycleRoundedIcon />}
          list={
            <List component="div" disablePadding>
              {context.accessible("event_templates") && (
                <CustomChildItem
                  label={<Translate i18nKey="event_templates" />}
                  icon={<EventIcon />}
                  route="/template"
                  styleRoutes={["/template"]}
                  nested
                />
              )}
              {context.accessible("event_rules_configuration") && (
                <CustomChildItem
                  label={<Translate i18nKey="event_rules_configuration" />}
                  icon={<PermDataSettingIcon />}
                  route="/events/rulesconfiguration"
                  styleRoutes={["/events/rulesconfiguration"]}
                  nested
                />
              )}
              {context.accessible("event_scheduling") && (
                <CustomChildItem
                  label={<Translate i18nKey="event_scheduling" />}
                  icon={<ScheduleIcon />}
                  route="/events"
                  styleRoutes={["/events", "/events/new", "/event/:id"]}
                  nested
                />
              )}
              {context.accessible("session_management") && (
                <CustomChildItem
                  label={<Translate i18nKey="session_management" />}
                  icon={<MemoryIcon />}
                  route="/sessions"
                  styleRoutes={["/sessions", "/session/new", "/session/edit"]}
                  nested
                />
              )}
              {context.accessible("branded_reports") && (
                <CustomChildItem
                  label={<Translate i18nKey="branded_reports" />}
                  icon={<MemoryIcon />}
                  route="/branded-reports"
                  styleRoutes={["/branded-reports"]}
                  nested
                />
              )}
              {context.accessible("esm_scheduling") && (
                <CustomChildItem
                  label={<Translate i18nKey="esm_scheduling" />}
                  icon={<ScheduleIcon />}
                  route="/esm-scheduling"
                  styleRoutes={["/esm-scheduling"]}
                  nested
                />
              )}
              {context.accessible("ts_cards") && (
                <CustomChildItem
                  label={<Translate i18nKey="ts_cards" />}
                  icon={<PostAddIcon />}
                  route="/ts-cards"
                  styleRoutes={["/ts-cards"]}
                  nested
                />
              )}
            </List>
          }
        />
      )}

      {context.accessible("race_control") && (
        <CustomParentItem
          open={openControl}
          onClick={handleControlClick}
          label={<Translate i18nKey="race_control" />}
          icon={<FlagIcon />}
          list={
            <List component="div" disablePadding>
              {context.accessible("control_board") && (
                <CustomChildItem
                  label={<Translate i18nKey="control_board" />}
                  icon={<ViewQuiltIcon />}
                  route="/control-board"
                  styleRoutes={["/control-board"]}
                  nested
                />
              )}
              {context.accessible("event_log") && (
                <CustomParentItem
                  styling={{ paddingLeft: "32px", paddingTop: 0 }}
                  open={openEventLog}
                  onClick={handleEventLogClick}
                  label={<Translate i18nKey="critical_event_log" />}
                  icon={<ErrorOutlineIcon />}
                  list={
                    <List component="div" disablePadding>
                      {context.accessible("event_log_read_only") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="event_log_read_only" />}
                          icon={<ScreenLockLandscapeIcon />}
                          route="/event-log-ro"
                          styleRoutes={["/event-log-ro"]}
                          nested
                        />
                      )}
                      {context.accessible("event_log_listing") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="event_log" />}
                          icon={<ListAltIcon />}
                          route="/event-log"
                          styleRoutes={["/event-log", "/event-log-entries", "/event-log-entries/new"]}
                          nested
                        />
                      )}
                      {context.accessible("retirement_logs") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="retirement_logs" />}
                          icon={<ListAltIcon />}
                          route="/retirement-logs"
                          styleRoutes={["/retirement-logs", "/retirement-logs/new"]}
                          nested
                        />
                      )}
                    </List>
                  }
                />
              )}
              {context.accessible("manual_timing") && (
                <CustomParentItem
                  styling={{ paddingLeft: "32px", paddingTop: 0 }}
                  open={openManual}
                  onClick={handleManual}
                  label={<Translate i18nKey="manual_timing" />}
                  icon={<AvTimerIcon />}
                  list={
                    <List component="div" disablePadding>
                      {context.accessible("passings") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="passings" />}
                          icon={<EmojiFlagsIcon />}
                          route="/passings"
                          styleRoutes={["/passings"]}
                          nested
                        />
                      )}
                      {context.accessible("laps") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="laps" />}
                          icon={<Rotate90DegreesCcwIcon />}
                          route="/laps"
                          styleRoutes={["/laps"]}
                          nested
                        />
                      )}
                      {context.accessible("sectors") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="sectors" />}
                          icon={<LinearScaleIcon />}
                          route="/sectors"
                          styleRoutes={["/sectors"]}
                          nested
                        />
                      )}
                      {context.accessible("penalties_credits") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="penalties_credits" />}
                          icon={<ExposureIcon />}
                          route="/penalties"
                          styleRoutes={["/penalties"]}
                          nested
                        />
                      )}
                    </List>
                  }
                />
              )}
              {context.accessible("on_track_events") && (
                <CustomParentItem
                  styling={{ paddingLeft: "32px", paddingTop: 0 }}
                  open={openTrackEvent}
                  onClick={handleOnTrackEventClick}
                  label={<Translate i18nKey="on_track_events" />}
                  icon={<ListIcon />}
                  list={
                    <List component="div" disablePadding>
                      {context.accessible("on_track_read_only") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="on_track_read_only" />}
                          icon={<ScreenLockLandscapeIcon />}
                          route="/on-track-read-only"
                          styleRoutes={["/on-track-read-only"]}
                          nested
                        />
                      )}
                      {context.accessible("on_track") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="on_track" />}
                          icon={<WebIcon />}
                          route="/on-track"
                          styleRoutes={["/on-track"]}
                          nested
                        />
                      )}
                      {context.accessible("predicted_position") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="predicted_position" />}
                          icon={<SwapVerticalCircleIcon />}
                          route="/predicted_position"
                          styleRoutes={["/predicted_position"]}
                          nested
                        />
                      )}
                      {context.accessible("retirements") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="on_track_actions" />}
                          icon={<BackspaceIcon />}
                          route="/retirements"
                          styleRoutes={["/retirements"]}
                          nested
                        />
                      )}
                      {context.accessible("incidents") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="incidents" />}
                          icon={<WarningIcon />}
                          route="/#"
                          styleRoutes={["/#"]}
                          nested
                        />
                      )}
                    </List>
                  }
                />
              )}
              {context.accessible("qualification") && (
                <CustomChildItem
                  label={<Translate i18nKey="qualification" />}
                  icon={<FunctionsIcon />}
                  route="/qualification-dashboard"
                  styleRoutes={["/qualification-dashboard"]}
                  nested
                />
              )}
              {context.accessible("grid_formation") && (
                <CustomChildItem
                  label={<Translate i18nKey="grid_formation" />}
                  icon={<FormatListNumberedIcon />}
                  route="/grid-formation"
                  styleRoutes={["/grid-formation"]}
                  nested
                />
              )}
              {context.accessible("start_time") && (
                <CustomChildItem
                  label={<Translate i18nKey="start_time" />}
                  icon={<AlarmOnIcon />}
                  route="/start-time"
                  styleRoutes={["/start-time"]}
                  nested
                />
              )}
              {context.accessible("leaderboard_race_results") && (
                <CustomChildItem
                  label={<Translate i18nKey="race_results" />}
                  icon={<FormatListNumberedIcon />}
                  route="/race-results"
                  styleRoutes={["/race-results"]}
                  nested
                />
              )}
            </List>
          }
        />
      )}

      {context.accessible("machine_management") && (
        <CustomParentItem
          open={openMachine}
          onClick={handleMachineClick}
          label={<Translate i18nKey="machine_management" />}
          icon={<MemoryIcon />}
          list={
            <List component="div" disablePadding>
              {context.accessible("vehicles") && (
                <CustomChildItem
                  label={<Translate i18nKey="vehicles" />}
                  icon={<DriveEtaIcon />}
                  route="/machines"
                  styleRoutes={["/machines"]}
                  nested
                />
              )}
              {context.accessible("gps_trackers") && (
                <CustomChildItem
                  label={<Translate i18nKey="gps_trackers" />}
                  icon={<GpsFixedIcon />}
                  route="/machines/gpstrackers"
                  styleRoutes={["/machines/gpstrackers"]}
                  nested
                />
              )}
              {context.accessible("assignment_dashboard") && (
                <CustomChildItem
                  label={<Translate i18nKey="assignment_dashboard" />}
                  icon={<LabelIcon />}
                  route="/machines/trackers"
                  styleRoutes={["/machines/trackers"]}
                  nested
                />
              )}
            </List>
          }
        />
      )}
      {context.accessible("competitor_management") && (
        <CustomParentItem
          open={openCompetManagement}
          onClick={handleCompetManagementClick}
          label={<Translate i18nKey="competitor_management" />}
          icon={<GroupWorkIcon />}
          list={
            <List component="div" disablePadding>
              {context.accessible("event_dashboard") && (
                <CustomParentItem
                  styling={{ paddingLeft: "32px", paddingTop: 0 }}
                  open={openEventDashboard}
                  onClick={handleEventDashboardClick}
                  label={<Translate i18nKey="event_dashboard" />}
                  icon={<ErrorOutlineIcon />}
                  list={
                    <List component="div" disablePadding>
                      {context.accessible("status_competitor_stops") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="status_competitor_stops" />}
                          icon={<ScreenLockLandscapeIcon />}
                          route="/status-competitor-stops"
                          styleRoutes={["/status-competitor-stops"]}
                          nested
                        />
                      )}
                      {context.accessible("machines_tracker_transponder") && (
                        <CustomChildItem
                          styling={{ paddingLeft: "48px" }}
                          label={<Translate i18nKey="machines_tracker_transponder" />}
                          icon={<ListAltIcon />}
                          route="/machines-tracker-transponder"
                          styleRoutes={["/machines-tracker-transponder"]}
                          nested
                        />
                      )}
                    </List>
                  }
                />
              )}
              {context.accessible("bulletin_read_only") && (
                <CustomChildItem
                  label={<Translate i18nKey="bulletin_read_only" />}
                  icon={<FormatListBulletedIcon className="icon-ntt" />}
                  route="/bulletin-read-only"
                  styleRoutes={["/bulletin-read-only"]}
                  nested
                />
              )}
              {context.accessible("bulletin") && (
                <CustomChildItem
                  label={<Translate i18nKey="bulletin" />}
                  icon={<FormatListBulletedIcon className="icon-ntt" />}
                  route="/bulletin"
                  styleRoutes={["/bulletin", "/bulletin/new"]}
                  nested
                />
              )}
              {context.accessible("registrations") && (
                <CustomChildItem
                  label={<Translate i18nKey="registrations" />}
                  icon={<GroupIcon className="icon-ntt" />}
                  route="/registrations"
                  styleRoutes={["/registrations"/*, "/registrations/details"*/]}
                  nested
                />
              )}
              {context.accessible("notifications") && (
                <CustomChildItem
                  label={<Translate i18nKey="notifications" />}
                  icon={<NotificationsActiveIcon />}
                  route="/notifications"
                  styleRoutes={["/notifications"]}
                  nested
                />
              )}
              {context.accessible("approved_competitors") && (
                <CustomChildItem
                  label={<Translate i18nKey="approved_competitors" />}
                  icon={<NotificationsActiveIcon />}
                  route="/approved-competitors"
                  styleRoutes={["/approved-competitors"]}
                  nested
                />
              )}
            </List>
          }
        />
      )}
      {/* These boys are not nested */}

      {context.accessible("user_management") && (
        <CustomChildItem
          label={<Translate i18nKey="user_management" />}
          icon={<GroupIcon className="icon-ntt" />}
          route="/users"
          styleRoutes={["/users", "/users/new"]}
        />
      )}
      {context.accessible("paddock_management") && (
        <CustomChildItem
          label={<Translate i18nKey="paddock_management" />}
          icon={<GroupIcon className="icon-ntt" />}
          route="/paddock"
          styleRoutes={["/paddock", "/paddock/details/:id1/:id2"]}
        />
      )}
      {context.accessible("documentation") && (
        <CustomChildItem
          label={<Translate i18nKey="documentation" />}
          icon={<FindInPageIcon className="icon-ntt" />}
          route="/documentation"
          styleRoutes={["/documentation"]}
        />
      )}
      {context.accessible("reports") && (
        <CustomChildItem
          label={<Translate i18nKey="reporting" />}
          icon={<DescriptionIcon className="icon-ntt" />}
          route="/reports"
          styleRoutes={["/reports"]}
        />
      )}
    </List>
  );
}
