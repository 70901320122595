import React from "react";
import { Auth } from "aws-amplify";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "../theme";
import Toast from "../common/Toast";
import AppTitle from "../common/AppTitle";
import BtnLink from "../common/BtnLink";
import Translate from "../common/Translate";
import { Link } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = themeSecond => ({
  root: {
    "& input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
      boxShadow: "0 0 0 30px #242a37 inset !important",
      "-webkit-box-shadow": "0 0 0 30px #242a37 inset !important",
      "-webkit-text-fill-color": "#fff !important"
    },
    height: "100vh",
    "& .MuiInput-underline:before": {
      borderBottomColor: "rgba(255,255,255,0.3)"
    },
    '& label, & label[data-shrink = "true"]': {
      color: "#7F8FA4",
      fontSize: "16px",
      fontFamily: "MuseoSans500"
    }
  },
  paper: {
    margin: themeSecond.spacing(0.75, 4),
    display: "flex",
    flexDirection: "column",
    background: "#242A37",
    color: "#fff",
    padding: theme.spacing(5.5, 8, 2.5)
  },
  title: {
    marginBottom: theme.spacing(2.75)
  },
  subTitle: {
    paddingBottom: theme.spacing(3)
  },
  forgotPass: {
    color: "#fff",
    fontSize: "16px",
    fontFamily: "MuseoSans300",
    background: "none!important",
    border: "none",
    padding: "0!important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  bgStyle: {
    background: "#181C28 !important",
    padding: "2% 28%"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    color: "#fff",
    "& input": {
      color: "#fff"
    }
  },
  submit: {
    margin: theme.spacing(0, 0, 4.5),
    fontSize: "24px",
    disabled: {
      color: "#fff"
    }
  },
  submitDisabled: {
    margin: theme.spacing(3, 0, 2),
    fontSize: "24px",
    color: "#FFF"
  },
  checkbox: {
    margin: theme.spacing(3, 0, 2)
  },
  agree: {
    margin: "29px 0 2px"
  }
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "spectator",
      checked: false,
      error: "",
      userName: "",
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      license: ""
    };

    this.goToSignIn = this.goToSignIn.bind(this);
  }

  goToSignIn() {
    // to switch the authState to 'signIn'
    this.props.onStateChange("signIn", {});
  }

  handleTabChange = (event, newValue) => {
    this.setState({ userType: newValue });
  };

  validateForm = () => {
    this.setState({
      checked: !this.state.checked
    });
  };

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.signUp();
    }
  };

  render() {
    const { classes } = this.props;
    const { userType, checked, error } = this.state;

    return (
      <div>
        {this.props.authState === "signUp" && (
          <Grid container component="main" className={classes.root}>
            <Toast>{error}</Toast>

            <CssBaseline />
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              elevation={6}
              square
              className={classes.bgStyle}
            >
              {/*to do : wrap the App into MuiThemeProvider*/}
              <MuiThemeProvider theme={theme}>
                <AppTitle big />
                <Grid>
                  <Paper className={classes.paper}>
                    <Typography variant="h1" className={classes.title}>
                      <Translate i18nKey="create_account" />
                    </Typography>
                    <Tabs
                      value={userType}
                      indicatorColor="primary"
                      textColor="primary"
                      onChange={this.handleTabChange}
                      aria-label="disabled tabs example"
                      centered
                      variant="fullWidth"
                    >
                      <Tab
                        value="spectator"
                        label={<Translate i18nKey="spectator" />}
                      />
                      <Tab
                        value="competitor"
                        label={<Translate i18nKey="competitor" />}
                      />
                    </Tabs>
                    <form id="signUpForm" className={classes.form}>
                      <TextField
                        fullWidth
                        className={classes.form}
                        margin="normal"
                        id="username"
                        key="username"
                        name="username"
                        type="text"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                        label={<Translate i18nKey="username" />}
                        autoComplete="username"
                        autoFocus
                      />
                      <TextField
                        fullWidth
                        className={classes.form}
                        label={<Translate i18nKey="Email" />}
                        autoComplete="email"
                        margin="normal"
                        id="email"
                        key="email"
                        name="email"
                        type="email"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                      />
                      <TextField
                        fullWidth
                        className={classes.form}
                        label={<Translate i18nKey="first_name" />}
                        margin="normal"
                        id="firstName"
                        key="firstName"
                        name="firstName"
                        type="text"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                      />
                      <TextField
                        fullWidth
                        className={classes.form}
                        label={<Translate i18nKey="last_name" />}
                        margin="normal"
                        id="lastName"
                        key="lastName"
                        name="lastName"
                        type="text"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                      />

                      {/**
                          //TextField shows based on the type of user (from the props)
                          // in our case if the user is spectator this fild will be hidden
                          //else it will be shown
                      */}
                      <TextField
                        fullWidth
                        className={classes.form}
                        name="license"
                        margin="normal"
                        label={<Translate i18nKey="license" />}
                        type="text"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                        style={{
                          display: userType === "spectator" ? "none" : "block"
                        }}
                      />
                      <TextField
                        fullWidth
                        className={classes.form}
                        margin="normal"
                        label={<Translate i18nKey="password" />}
                        type="password"
                        id="password"
                        key="password"
                        name="password"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                        autoComplete="current-password"
                      />

                      <TextField
                        fullWidth
                        className={classes.form}
                        margin="normal"
                        label={<Translate i18nKey="re_enter_password" />}
                        type="password"
                        name="confirmPassword"
                        onChange={this.handleInputChange}
                        onKeyPress={this.handleKeyPress}
                      />
                      <div className={classes.agree}>
                        <Checkbox
                          style={{ paddingLeft: 0 }}
                          checked={checked}
                          color="primary"
                          onClick={this.validateForm}
                        />

                        <Typography
                          variant="body2"
                          style={{ display: "inline-block" }}
                        >
                          <Translate i18nKey="i_agree_with" />
                        </Typography>

                        <Link href="https://www.iomttraces.com/legal/competition-terms-and-conditions" target="_blank">
                          {}
                          <Translate i18nKey="terms_and_conditions" />
                        </Link>
                      </div>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => this.signUp()}
                        disabled={!checked}
                      >
                        {this.state.loading ? (
                          <CircularProgress
                            style={{ color: theme.palette.common.white }}
                          />
                        ) : (
                          <Translate i18nKey="sign_up" />
                        )}
                      </Button>
                    </form>
                    <Grid container>
                      <Grid item xs>
                        <Typography
                          variant="body2"
                          style={{ display: "inline-block" }}
                        >
                          <Translate i18nKey="have_an_account?" />
                        </Typography>
                        <BtnLink
                          click={this.goToSignIn}
                          label={<Translate i18nKey="sign_in" />}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </MuiThemeProvider>
            </Grid>
          </Grid>
        )}
      </div>
    );
  }

  signUp() {
    const {
      password,
      confirmPassword,
      username,
      email,
      firstName,
      lastName,
      userType
    } = this.state;

    if (password !== confirmPassword) {
      this.setState({
        error: "Passwords didn't match. Please try again."
      });
      return;
    }

    this.setState({ loading: true });
    Auth.signUp({
      username: username,
      password: password,
      attributes: {
        email: email,
        given_name: firstName,
        family_name: lastName,
        "custom:user_category": userType
      }
    })
      .then(data => {
        this.props.onStateChange("confirmSignUp", data.user.username);
        this.setState({ loading: false });
      })
      .catch(err => {
        this.setState({ error: err.message || err.log });
        this.setState({ loading: false });
      });
  }
}

export default withStyles(useStyles)(SignUp);
