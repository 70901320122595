import React from "react";
import { makeStyles, Typography, IconButton, Chip } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0px 10px 0px 10px',
        width: '100%',
        height: '100px',
        // backgroundColor: 'pink',
        display: 'flex',
        flexDirection: 'row',
        borderTop: 'solid #242A37 1px',
        '&:hover': {
            backgroundColor: '#e4e4e4'
        }
    },
    dataContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    penaltyDataContainer: {
        padding: '10px 10px 10px 20px',
        // backgroundColor: 'gray',
        display: 'flex',
        flexDirection: 'column',
    },
    penaltyDot: {
        height: '13px',
        width: '13px',
        borderRadius: '80px'
    },
    closeButton: {
        borderRadius: '80px',
    },
    number: {
        border: "solid gray 1px",
        borderRadius: "20px",
        minWidth: "60px",
        textAlign: "center",
    },
}));

export default function NotificationTemplate(props) {
    const classes = useStyles();
    const moment = require('moment-timezone');
    const { input, removeRow } = props;

    const fromUTCtoUK = utc_iso_timestamp => {
        return moment(utc_iso_timestamp).tz('Europe/London').format('DD/MM/YYYY HH:mm:ss');
    }

    return (
        <div className={classes.container}>
            <div id='competitor_number_container' className={classes.dataContainer} style={{ width: '60px' }}>
                <Chip
                    label={
                        <Typography
                            variant="h1"
                            component="h1"
                            style={{
                                color:
                                    input.class
                                        ? `rgb${input.class.number_color}`
                                        : "black",
                                fontSize: 32,
                            }}
                        >
                            {input.competitor.number}
                        </Typography>
                    }
                    variant="outlined"
                    className={classes.number}
                    style={{
                        backgroundColor:
                            input.class
                                ? `rgb${input.class.plate_color}`
                                : "white",
                    }}
                />
            </div>
            <div id='penalty_data_container' className={classes.penaltyDataContainer} style={{ width: '460px' }}>
                <div id='competitor_name'>
                    <Typography variant='h1' style={{ fontSize: '24px', height: '30px' }}>
                        {input.competitor ? `${input.competitor.firstname} ${input.competitor.lastname}` : ''}
                    </Typography>
                </div>
                <div id='speed_n_penalty' style={{ paddingLeft: '10px', height: '30px' }}>
                    <Typography>
                        <span style={{ marginRight: '5px', fontWeight: 'bold', color: '#4d4d4d' }}>
                            {`Speed:`}
                        </span>
                        <span style={{ marginRight: '30px' }}>
                            {input.penaltyPLspeed ? `${input.penaltyPLspeed} km/h` : 'N/A'}
                        </span>
                        <span style={{ marginRight: '5px', fontWeight: 'bold', color: '#4d4d4d' }}  >
                            {`Penalty:`}
                        </span>
                        <span>
                            {input.speed_penalty ? input.speed_penalty === 'disqualified' ? 'Disqualified' : `${input.speed_penalty} sec` : 'N/A'}
                        </span>
                    </Typography>
                </div>
                <div id='date_n_time'>
                    <Typography style={{ fontSize: '14px', fontWeight: 'bold', color: '#4d4d4d' }}>
                        {fromUTCtoUK(input.created_on)}
                    </Typography>
                </div>
            </div>
            <div id='close_notification' className={classes.dataContainer} style={{ width: '55px' }}>
                <div>
                    <IconButton className={classes.closeButton} onClick={() => removeRow(input.id)}>
                        <CloseIcon size="small" />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}