export const RaceAdministrator = "RA";
export const ClerkDeputyClerk = "COC";
export const ChiefDeputyChief = "CTK";
export const EventSafetyOfficer = "ESO";
export const ChiefMedicalOfficer = "CMO";
export const Police = "POLICE";
export const Fire = "FIRE";
export const Ambulance = "AMBULANCE";
export const TMControls = "TC";
export const CourseCars = "CC";
export const LiveScreenDisplay = "LSD";
export const GPSAPI = "GPSAPI";
export const Media = "MEDIA";
export const TTOrganizer = "TTO";
export const CTTMGPOrganizer = "MGPO";
export const RaceOfficeStaff = "ROS";
export const TimingAdministrator = "TA";
export const Scouts = "SCOUTS";
export const MotorsportTeam = "MT";
export const PaddockManager = "PDM";
export const TechnicalOfficial = "TO";
export const TT = "TT";
export const TTPARADE = "TTPARADE";
export const CTT = "CTT";
export const CTTPARADE = "CTTPARADE";
export const MGP = "MGP";
export const MGPPARADE = "MGPPARADE";
export const Others = "OTHERS";
export const TTC = "TTC";
export const ELA = "ELA";
export const PO = "PO";
export const RETO = "RETO";
export const ACURET = "ACURET";
export const CTO = "CTO";
export const WelfareOfficer = "WO";
export const OnTrackReadOnly = "OTRO";

export const config = {
  s3: {
    region: process.env.REACT_APP_REGION || "S3_UPLOADS_BUCKET_REGION",
    bucket: "S3_UPLOADS_BUCKET_NAME",
  },
  apiGateway: {
    name: "IOM",
    region: process.env.REACT_APP_REGION || "eu-west-1",
    endpoint:
      process.env.REACT_APP_API ||
      "https://jy0g1nli18.execute-api.eu-west-1.amazonaws.com/api",
  },
  cognito: {
    backOfficeUserPool: {
      region: process.env.REACT_APP_BO_REGION || "eu-west-1",
      userPoolId:
        process.env.REACT_APP_BO_USER_POOL_ID || "eu-west-1_wn0OUsUhz",
      userPoolWebClientId:
        process.env.REACT_APP_BO_CLIENT_ID || "11kdaq2ft1hl65r2kv4lr3nv6i",
    },
    frontOfficeUserPool: {
      userPoolId:
        process.env.REACT_APP_FO_USER_POOL_ID || "eu-west-1_aBVx9OsGp",
    },
    identityPoolId:
      process.env.REACT_APP_IDENTITY_POOL_ID ||
      "eu-west-1:9c47fc36-31b7-49f0-9a0f-5d8f8746153d",
  },
  fontSize: {
    small: process.env.REACT_APP_FONT_SIZE_SM || "small",
    medium: process.env.REACT_APP_FONT_SIZE_MD || "medium",
    large: process.env.REACT_APP_FONT_SIZE_LG || "large",
    xlarge: process.env.REACT_APP_FONT_SIZE_XL || "x-large",
  },
  websocket: {
    endpoint:
      process.env.REACT_APP_WEBSOCKET_API ||
      "55i9ec295a.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_WEBSOCKET_REGION || "eu-west-1",
  },
  esmWebsocket: {
    endpoint:
      process.env.REACT_APP_ESM_WEBSOCKET_API ||
      "3k01m2ivne.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_ESM_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_ESM_WEBSOCKET_REGION || "eu-west-1",
  },
  timingWebsocket: {
    endpoint:
      process.env.REACT_APP_TIMING_WEBSOCKET_API ||
      "hk1uto55vk.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_TIMING_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_TIMING_WEBSOCKET_REGION || "eu-west-1",
  },
  eventLogStreamingWebsocket: {
    endpoint:
      process.env.REACT_APP_WEBSOCKET_EVENT_LOG_API ||
      "gbqksr4e54.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_TIMING_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_TIMING_WEBSOCKET_REGION || "eu-west-1",
  },
  penaltiesNotificationsWebsocket: {
    endpoint:
      process.env.REACT_APP_WEBSOCKET_NOTIFICATIONS_API ||
      "oleeo5fvn1.execute-api.eu-west-1.amazonaws.com",
    env: process.REACT_APP_TIMING_WEBSOCKET_ENV || "api",
    region: process.REACT_APP_TIMING_WEBSOCKET_REGION || "eu-west-1",
  },
  quickSight: {
    region: process.env.REACT_APP_REGION || "eu-west-1",
    url:
      process.env.REACT_APP_QUICKSIGHT_URL ||
      "https://eu-west-1.quicksight.aws.amazon.com/sn/start?directory_alias=rcs-staging",
  },
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || "AIzaSyDvx5IM2bbHnOb4sY-0aISwH8bAxH7ecS4", //'AIzaSyCFSUrjfz4BH15z7XvgoqvRFTShhFa65lc',
  imageCompressionOptions: {
    maxSizeMB: // (default: Number.POSITIVE_INFINITY)
      4,
    maxWidthOrHeight: // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
      // Please check the Caveat part for details.
      undefined,
    onProgress: // optional, a function takes one progress argument (percentage from 0 to 100) 
      progress => {
        console.log('Compression Progress:', progress);
      },
    useWebWorker: // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
      true,
    // following options are for advanced users
    maxIteration: // optional, max number of iteration to compress the image (default: 10) 
      10,
    // exifOrientation: number,    // optional, see https://stackoverflow.com/a/32490603/10395024
    // fileType: string,           // optional, fileType override
    // initialQuality: number      // optional, initial quality value between 0 and 1 (default: 1)
  },
  documentationLink: process.env.REACT_APP_DOCUMENTATION_FILE || 'https://iom-howto-stag.s3.eu-west-1.amazonaws.com/Documentation+-+table+of+content.chm', 
  roles: [
    {
      role: RaceAdministrator,
      name: "Race Administrator",
      control: {
        functionalities: [
          "nct_gps",
          "rcs_home",
          "timing",
          "gps_trackers",
          "vehicles",
          "assignment_dashboard",
          "race_management",
          "race_control",
          "on_track_events",
          "on_track_read_only",
          "on_track",
          "control_board",
          "manual_timing",
          "passings",
          "sectors",
          "laps",
          "penalties_credits",
          "predicted_position",
          "retirements",
          "penalties",
          "leaderboard_race_results",
          "grid_formation",
          "machine_management",
          "system_settings",
          "custom_fields",
          "licence_setup",
          "sector_management",
          "competitor_management",
          "team_managers",
          "notifications",
          "user_management",
          "registrations",
          "reporting",
          "help",
          "event_rules_configuration",
          "event_templates",
          "event_scheduling",
          "paddock_management",
          "event_log_read_only",
          "event_log",
          "event_log_listing",
          "session_management",
          "passings",
          "qualification",
          "grid_formation",
          "leaderboard_race_results",
          "start_time",
          "laps",
          "control_board",
          "retirements",
          "predicted_position",
          "branded_reports",
          "retirement_logs",
          "esm_scheduling",
          "documentation",
          "approved_competitors",
          "esm",
          "esm_briefing",
          "esm_transponder_assignment",
          "esm_tx_eligibility",
          "esm_tx_eligibility_manual",
          "esm_clothing_inspection",
          "esm_technical_inspection",
          "esm_clerk_of_course",
          "esm_signing_on",
          "esm_medical_stops",
          "esm_helmet_inspection",
          "ts_cards",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "bulletin",
        ],
        routes: [
          "/profile",
          "/services",
          "/events",
          "/events/new",
          "/event/:id",
          "/template",
          "/users",
          "/users/new",
          "/team-managers",
          "/notifications",
          "/machines",
          "/gps/nct",
          "/gps/timing",
          "/machines/trackers",
          "/machines/gpstrackers",
          "/registrations",
          "/events/rulesconfiguration",
          "/paddock",
          "/paddock/details/:id1/:id2",
          "/help",
          "/on-track",
          "/on-track-read-only",
          "/event-log-ro",
          "/event-log",
          "/event-log-entries/new",
          "/event-log-entries",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/penalties",
          "/passings",
          "/qualification-dashboard",
          "/grid-formation",
          "/sectors",
          "/race-results",
          "/start-time",
          "/laps",
          "/control-board",
          "/retirements",
          "/predicted_position",
          "/gps/timing/qualification/event/:event_id/:event_name/class/:class_id/:class_name",
          "/gps/timing/grid/session/:session_id/:session_name/:color/:day",
          "/gps/timing/results/session/:session_id/:session_name/:day",
          "/branded-reports",
          "/retirement-logs",
          "/retirement-logs/new",
          "/esm-scheduling",
          "/documentation",
          "/registrations/new",
          "/registrations/details",
          "/approved-competitors",
          "/esm",
          "/esm/briefing",
          "/esm/transponder_assignment",
          "/esm/tx-eligibility",
          "/esm/tx-eligibility-manual",
          "/esm/clothing-inspection",
          "/esm/technical_inspection",
          "/esm/clerk-of-course",
          "/esm/signing_on",
          "/esm/medical-stops",
          "/esm/helmet_inspection",
          "/ts-cards",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/bulletin",
          "/bulletin/new",
          "/follow_leader_v2"
        ],
      },
    },
    {
      role: ChiefMedicalOfficer,
      name: "Chief Medical Officer",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "docs",
          "nct_gps",
          "event_log",
          // "event_log_listing",
          "retirements",
          // "retirement_logs",
          "competitor_management",
          "documentation",
          "approved_competitors",
          "esm",
          "esm_medical_stops",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "esm_technical_inspection",
          "bulletin_read_only",
          "esm_helmet_inspection",
          "esm_clothing_inspection",
          "race_control",
          "event_log_read_only"
        ],
        routes: [
          "/profile",
          "/gps/timing",
          "/gps/timing/qualification/event/:event_id/:event_name/class/:class_id/:class_name",
          "/gps/timing/grid/session/:session_id/:session_name/:color/:day",
          "/gps/timing/results/session/:session_id/:session_name/:day",
          "/services",
          "/help",
          "/gps/nct",
          // "/retirements",
          // "/retirement-logs",
          // "/retirement-logs/new",
          "/documentation",
          "/approved-competitors",
          "/registrations/details",
          "/esm",
          "/esm/medical-stops",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          // "/event-log",
          // "/event-log-entries/new",
          // "/event-log-entries",
          "/esm/technical_inspection",
          "/bulletin-read-only",
          "/esm/clothing-inspection",
          "/esm/helmet_inspection",
          "/event-log-ro",
        ],
      },
    },
    {
      role: ClerkDeputyClerk,
      name: "Clerk & Deputy Clerk of the Course",
      control: {
        functionalities: [
          "rcs_home",
          "docs",
          "nct_gps",
          "timing",
          "race_management",
          "event_rules_configuration",
          "session_management",
          "race_control",
          "control_board",
          "manual_timing",
          "passings",
          "laps",
          "sectors",
          "penalties_credits",
          "event_log",
          "event_log_listing",
          "on_track_events",
          "predicted_position",
          "retirements",
          "qualification",
          "grid_formation",
          "competitor_management",
          "registrations",
          "notifications",
          "approved_competitors",
          "event_log_read_only",
          "documentation",
          "event_scheduling",
          "esm",
          "esm_briefing",
          "esm_transponder_assignment",
          "esm_signing_on",
          "esm_clerk_of_course",
          "esm_medical_stops",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "esm_technical_inspection",
          "bulletin_read_only",
        ],
        routes: [
          "/profile",
          "/services",
          "/help",
          "/gps/nct",
          "/gps/timing",
          "/events/rulesconfiguration",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/control-board",
          "/passings",
          "/laps",
          "/sectors",
          "/penalties",
          "/predicted_position",
          "/qualification-dashboard",
          "/grid-formation",
          "/registrations",
          "/notifications",
          "/retirements",
          "/event-log-ro",
          "/event-log",
          "/event-log-entries/new",
          "/event-log-entries",
          "/approved-competitors",
          "/documentation",
          "/registrations/details",
          "/events",
          "/events/new",
          "/event/:id",
          "/esm",
          "/esm/briefing",
          "/esm/transponder_assignment",
          "/esm/clerk-of-course",
          "/esm/signing_on",
          "/esm/medical-stops",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/esm/technical_inspection",
          "/bulletin-read-only",
        ],
      },
    },
    {
      role: ChiefDeputyChief,
      name: "Chief & Deputy Chief Timekeeper",
      control: {
        functionalities: [
          "rcs_home",
          "docs",
          "nct_gps",
          "timing",
          "race_management",
          "event_rules_configuration",
          "session_management",
          "race_control",
          "manual_timing",
          "passings",
          "laps",
          "sectors",
          "penalties_credits",
          "on_track_events",
          "on_track",
          "predicted_position",
          "retirements",
          "qualification",
          "grid_formation",
          "start_time",
          "leaderboard_race_results",
          "competitor_management",
          "registrations",
          "notifications",
          "event_log",
          "event_log_listing",
          "control_board",
          "event_log_read_only",
          "documentation",
          "esm",
          "esm_transponder_assignment",
          "esm_clerk_of_course",
          "approved_competitors",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "bulletin_read_only"
        ],
        routes: [
          "/profile",
          "/services",
          "/help",
          "/gps/nct",
          "/gps/timing",
          "/events/rulesconfiguration",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/passings",
          "/laps",
          "/sectors",
          "/penalties",
          "/on-track",
          "/predicted_position",
          "/retirements",
          "/qualification-dashboard",
          "/grid-formation",
          "/start-time",
          "/race-results",
          "/registrations",
          "/retirements",
          "/notifications",
          "/control-board",
          "/event-log-ro",
          "/event-log",
          "/event-log-entries/new",
          "/event-log-entries",
          "/documentation",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/clerk-of-course",
          "/approved-competitors",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/registrations/details",
          "/bulletin-read-only",
          "/gps/timing/qualification/event/:event_id/:event_name/class/:class_id/:class_name",
          "/gps/timing/grid/session/:session_id/:session_name/:color/:day",
          "/gps/timing/results/session/:session_id/:session_name/:day",
        ],
      },
    },
    {
      role: EventSafetyOfficer,
      name: "Event Safety Officer",
      control: {
        functionalities: [
          "rcs_home",
          "docs",
          "nct_gps",
          "race_control",
          "timing",
          "event_log",
          "event_log_read_only",
          "documentation",
          "esm",
          "esm_signing_on",
          "competitor_management",
          "approved_competitors",
          "race_control",
          "on_track_events",
        ],
        routes: [
          "/profile",
          "/services",
          "/help",
          "/gps/nct",
          "/gps/timing",
          "/event-log",
          "/event-log-ro",
          "/documentation",
          "/esm",
          "/esm/signing_on",
          "/approved-competitors",
          "/registrations/details",
        ],
      },
    },
    {
      role: TMControls,
      name: "TM Controls",
      control: {
        functionalities: ["docs", "nct_gps", "documentation"],
        routes: ["/profile", "/services", "/help", "/gps/nct", "/documentation"],
      },
    },
    {
      role: Fire,
      name: "Fire",
      control: {
        functionalities: ["docs", "nct_gps", "documentation"],
        routes: ["/profile", "/services", "/help", "/gps/nct", "/documentation"],
      },
    },
    {
      role: Police,
      name: "Police",
      control: {
        functionalities: ["docs", "nct_gps", "documentation"],
        routes: ["/profile", "/services", "/help", "/gps/nct", "/documentation"],
      },
    },
    {
      role: Ambulance,
      name: "Ambulance",
      control: {
        functionalities: ["docs", "nct_gps", "documentation"],
        routes: ["/profile", "/services", "/help", "/gps/nct", "/documentation"],
      },
    },
    {
      role: CourseCars,
      name: "Course Cars",
      control: {
        functionalities: ["docs", "nct_gps", "documentation"],
        routes: ["/profile", "/services", "/help", "/gps/nct", "/documentation"],
      },
    },
    {
      role: LiveScreenDisplay,
      name: "Live Screen Display",
      control: {
        functionalities: [
          "rcs_home",
          "race_control",
          "nct_gps",
          "docs",
          "timing",
          "documentation",
          "race_control",
          "on_track_events",
        ],
        routes: [
          "/profile",
          "/services",
          "/gps/nct",
          "/help",
          "/gps/timing",
          "/documentation",
        ],
      },
    },
    {
      role: GPSAPI,
      name: "GPSAPI",
      control: {
        functionalities: ["documentation"],
        routes: ["/profile", "/services", "/documentation"],
      },
    },
    {
      role: Media,
      name: "Media",
      control: {
        functionalities: ["rcs_home", "docs", "timing", "documentation", "race_control", "on_track_events",],
        routes: ["/profile", "/services", "/help", "/gps/timing", "/documentation",],
      },
    },
    {
      role: TTOrganizer,
      name: "TT Organizer",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "competitor_management",
          "registrations",
          "notifications",
          "race_management",
          "event_scheduling",
          "race_control",
          "qualification",
          "grid_formation",
          "competitor_management",
          "approved_competitors",
          "documentation",
          "esm",
          "esm_transponder_assignment",
          "esm_clerk_of_course",
          "esm_signing_on",
          "esm_medical_stops",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "event_templates",
          "session_management",
          "bulletin_read_only",
          "race_control",
          "on_track_events",
          "race_management",
        ],
        routes: [
          "/profile",
          "/services",
          "/gps/timing",
          "/registrations",
          "/notifications",
          "/events",
          "/qualification-dashboard",
          "/grid-formation",
          "/approved-competitors",
          "/documentation",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/clerk-of-course",
          "/esm/signing_on",
          "/esm/medical-stops",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/template",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/registrations/new",
          "/registrations/details",
          "/event/:id",
          "/bulletin-read-only",
        ],
      },
    },
    {
      role: CTTMGPOrganizer,
      name: "CTT/MGP Organizer",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "competitor_management",
          "registrations",
          "notifications",
          "race_management",
          "event_scheduling",
          "race_control",
          "qualification",
          "grid_formation",
          "competitor_management",
          "approved_competitors",
          "documentation",
          "esm",
          "esm_clerk_of_course",
          "esm_signing_on",
          "esm_medical_stops",
          "esm_transponder_assignment",
          "event_templates",
          "session_management",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "bulletin_read_only",
          "race_control",
          "on_track_events",
        ],
        routes: [
          "/profile",
          "/services",
          "/gps/timing",
          "/registrations",
          "/notifications",
          "/events",
          "/event/:id",
          "/qualification-dashboard",
          "/grid-formation",
          "/approved-competitors",
          "/documentation",
          "/esm",
          "/esm/clerk-of-course",
          "/esm/signing_on",
          "/esm/medical-stops",
          "/esm/transponder_assignment",
          "/template",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/registrations/new",
          "/registrations/details",
          "/bulletin-read-only",
        ],
      },
    },
    {
      role: RaceOfficeStaff,
      name: "Race Office Staff",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "competitor_management",
          "registrations",
          "notifications",
          "approved_competitors",
          "documentation",
          "esm",
          "esm_transponder_assignment",
          "esm_clothing_inspection",
          "esm_signing_on",
          "esm_helmet_inspection",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "bulletin",
          "race_control",
          "on_track_events",
        ],
        routes: [
          "/profile",
          "/services",
          "/gps/timing",
          "/registrations",
          "/notifications",
          "/approved-competitors",
          "/documentation",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/clothing-inspection",
          "/esm/signing_on",
          "/esm/helmet_inspection",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/registrations/new",
          "/registrations/details",
          "/bulletin",
          "/bulletin/new",
        ],
      },
    },
    {
      role: TimingAdministrator,
      name: "Timing Administrator",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "race_management",
          "event_rules_configuration",
          "session_management",
          "race_control",
          "control_board",
          "manual_timing",
          "passings",
          "laps",
          "sectors",
          "penalties_credits",
          "on_track_events",
          "on_track",
          "predicted_position",
          "retirements",
          "qualification",
          "grid_formation",
          "start_time",
          "leaderboard_race_results",
          "branded_reports",
          "nct_gps",
          "event_log",
          "event_log_listing",
          "event_log_read_only",
          "documentation",
          "esm",
          "esm_transponder_assignment",
          "esm_tx_eligibility",
          "esm_tx_eligibility_manual",
          "bulletin_read_only"
        ],
        routes: [
          "/profile",
          "/services",
          "/gps/timing",
          "/events/rulesconfiguration",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/control-board",
          "/passings",
          "/laps",
          "/sectors",
          "/penalties",
          "/on-track",
          "/predicted_position",
          "/retirements",
          "/qualification-dashboard",
          "/grid-formation",
          "/start-time",
          "/race-results",
          "/branded-reports",
          "/event-log-ro",
          "/gps/nct",
          "/documentation",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/tx-eligibility",
          "/esm/tx-eligibility-manual",
          "/event-log",
          "/event-log-entries/new",
          "/event-log-entries",
          "/bulletin-read-only"
        ],
      },
    },
    {
      role: Scouts,
      name: "Scouts",
      control: {
        functionalities: ["rcs_home", "timing", "documentation", "on_track_events", "race_control",],
        routes: ["/profile", "/services", "/gps/timing", "/documentation",],
      },
    },
    {
      role: MotorsportTeam,
      name: "Motorsport Team",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "competitor_management",
          "registrations",
          "notifications",
          "paddock_management",
          "approved_competitors",
          "race_management",
          "event_scheduling",
          "documentation",
          "esm_scheduling",
          "event_templates",
          "session_management",
          "esm",
          "esm_briefing",
          "esm_transponder_assignment",
          "esm_tx_eligibility",
          "esm_tx_eligibility_manual",
          "esm_clothing_inspection",
          "esm_technical_inspection",
          "esm_clerk_of_course",
          "esm_signing_on",
          "esm_medical_stops",
          "esm_helmet_inspection",
          "race_control",
          "on_track_events",
        ],
        routes: [
          "/profile",
          "/services",
          "/gps/timing",
          "/registrations",
          "/notifications",
          "/paddock",
          "/paddock/details/:id1/:id2",
          "/approved-competitors",
          "/events",
          "/documentation",
          "/event/:id",
          "/esm-scheduling",
          "/template",
          "/sessions",
          "/session/new",
          "/session/edit",
          "/registrations/details",
          "/esm",
          "/esm/briefing",
          "/esm/transponder_assignment",
          "/esm/tx-eligibility",
          "/esm/tx-eligibility-manual",
          "/esm/clothing-inspection",
          "/esm/technical_inspection",
          "/esm/clerk-of-course",
          "/esm/signing_on",
          "/esm/medical-stops",
          "/esm/helmet_inspection",
          "/registrations/new",
          "/registrations/details",
        ],
      },
    },
    {
      role: PaddockManager,
      name: "Paddock Manager",
      control: {
        functionalities: [
          "rcs_home",
          "paddock_management",
          "competitor_management",
          "registrations",
          "documentation",
        ],
        routes: [
          "/profile",
          "/services",
          "/paddock",
          "/paddock/details/:id1/:id2",
          "/registrations",
          "/documentation"
        ],
      },
    },
    {
      role: TechnicalOfficial,
      name: "Technical Official",
      control: {
        functionalities: [
          "rcs_home",
          "competitor_management",
          "registrations",
          "documentation",
          "approved_competitors",
          "esm",
          "esm_transponder_assignment",
          "esm_tx_eligibility",
          "esm_tx_eligibility_manual",
          "esm_clothing_inspection",
          "esm_helmet_inspection",
          "esm_technical_inspection",
          "timing",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "race_management",
          "ts_cards",
        ],
        routes: [
          "/profile",
          "/services",
          "/registrations",
          "/documentation",
          "/approved-competitors",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/clothing-inspection",
          "/esm/helmet_inspection",
          "/esm/technical_inspection",
          "/gps/timing",
          "/gps/timing/qualification/event/:event_id/:event_name/class/:class_id/:class_name",
          "/gps/timing/grid/session/:session_id/:session_name/:color/:day",
          "/gps/timing/results/session/:session_id/:session_name/:day",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/ts-cards",
          "/registrations/details",
        ],
      },
    },
    {
      role: TT,
      name: "TT",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: TTPARADE,
      name: "TT PARADE",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: CTT,
      name: "CTT",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: CTTPARADE,
      name: "CTT PARADE",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: MGP,
      name: "MGP",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: MGPPARADE,
      name: "MGP PARADE",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: Others,
      name: "Others",
      control: {
        functionalities: [],
        routes: [],
      },
    },
    {
      role: TTC,
      name: "Transponder & Tracker Checking",
      control: {
        functionalities: [
          "rcs_home",
          "documentation",
          "esm",
          "esm_transponder_assignment",
          "esm_tx_eligibility",
          "esm_tx_eligibility_manual",
        ],
        routes: [
          "/profile",
          "/documentation",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/tx-eligibility",
          "/esm/tx-eligibility-manual",
        ],
      },
    },
    {
      role: ELA,
      name: "Event Log Administrator",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "reporting",
          "race_control",
          "event_log_read_only",
          "event_log",
          "event_log_listing",
          "documentation",
          "competitor_management",
          "approved_competitors"
        ],
        routes: [
          "/gps/timing",
          "/gps/timing/qualification/event/:event_id/:event_name/class/:class_id/:class_name",
          "/gps/timing/grid/session/:session_id/:session_name/:color/:day",
          "/gps/timing/results/session/:session_id/:session_name/:day",
          "/event-log-ro",
          "/event-log",
          "/event-log-entries/new",
          "/event-log-entries",
          "/profile",
          "/services",
          "/documentation",
          "/approved-competitors",
          "/registrations/details"
        ],
      },
    },
    {
      role: PO,
      name: "Press Officer",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "reporting",
          "documentation",
          "race_control",
          "on_track_events",
        ],
        routes: [
          "/profile",
          "/gps/timing",
          "/documentation",
        ],
      },
    },
    {
      role: RETO,
      name: "Retirement Officer",
      control: {
        functionalities: [
          "timing",
          "rcs_home",
          "race_control",
          "reporting",
          "event_log",
          "retirement_logs",
          "reporting",
          "documentation",
          "paddock_management",
          "competitor_management",
          "approved_competitors"
        ],
        routes: ["/profile", "/gps/timing", "/retirement-logs", "/retirement-logs/new", "/documentation", "/approved-competitors", "/paddock", "/paddock/details/:id1/:id2", "/approved-competitors", "/registrations/details"],
      },
    },
    {
      role: ACURET,
      name: "ACU Retirement",
      control: {
        functionalities: ["rcs_home", "reporting", "documentation"],
        routes: ["/profile", "/documentation"],
      },
    },
    {
      role: CTO,
      name: "Chief Technical Official",
      control: {
        functionalities: [
          "rcs_home",
          "timing",
          "race_management",
          "reporting",
          "retirements",
          "competitor_management",
          "registrations",
          "approved_competitors",
          "documentation",
          "esm",
          "esm_transponder_assignment",
          "esm_clothing_inspection",
          "esm_clerk_of_course",
          "esm_helmet_inspection",
          "esm_tx_eligibility",
          "esm_tx_eligibility_manual",
          "esm_technical_inspection",
          "ts_cards",
          "event_dashboard",
          "machines_tracker_transponder",
          "status_competitor_stops",
          "bulletin_read_only"
        ],
        routes: [
          "/retirements",
          "/gps/timing",
          "/registrations",
          "/profile",
          "/services",
          "/approved-competitors",
          "/documentation",
          "/esm",
          "/esm/transponder_assignment",
          "/esm/clothing-inspection",
          "/esm/clerk-of-course",
          "/esm/helmet_inspection",
          "/esm/technical_inspection",
          "/ts-cards",
          "/registrations/new",
          "/registrations/details",
          "/machines-tracker-transponder",
          "/status-competitor-stops",
          "/bulletin-read-only"
        ],
      },
    },
    {
      role: WelfareOfficer,
      name: "Welfare Officer",
      control: {
        functionalities: [
          "rcs_home",
          "race_control",
          "event_log",
          "event_log_listing",
          "competitor_management",
          "approved_competitors",
        ],
        routes: [
          "/profile",
          "/event-log",
          "/event-log-entries/new",
          "/event-log-entries",
          "/approved-competitors",
          "/registrations/new",
          "/registrations/details",
        ],
      },
    },
    {
      role: OnTrackReadOnly,
      name: "On Track Read Only",
      control: {
        functionalities: [
          "rcs_home",
          "race_control",
          "on_track_events",
          "on_track_read_only",
        ],
        routes: [
          "/on-track-read-only",
        ],
      },
    },
  ],
};
