import AWS from "aws-sdk";
import { config } from "../config";
const frontOfficeUserPoolId = config.cognito.frontOfficeUserPool.userPoolId;
const backOfficeUserPoolId = config.cognito.backOfficeUserPool.userPoolId;

export const getTeamManagers = (paginationToken, users = [], auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    AttributesToGet: [
      "email",
      "family_name",
      "given_name",
      "custom:user_category",
    ],
    PaginationToken: paginationToken,
    UserPoolId: frontOfficeUserPoolId,
  };

  return new Promise((resolve) => {
    identityProvider.listUsers(params, async function (error, data) {
      if (error) {
        console.log(JSON.stringify(error));
      } else {
        const allUsers = users.concat(data.Users);
        const nextToken = data.PaginationToken;
        if (nextToken) {
          await resolve(getTeamManagers(nextToken, allUsers, auth));
        } else {
          const teamManagers = allUsers

            .filter(
              (user) =>
                getValue(user, "custom:user_category") === "team_manager"
            )
            .map((user) => {
              return {
                org: user["custom:organization"],
                username: user["Username"],
                firstName: getValue(user, "given_name"),
                lastName: getValue(user, "family_name"),
                email: getValue(user, "email"),
                enabled: user["Enabled"],
                status: user["UserStatus"],
              };
            });

          await resolve(teamManagers);
        }
      }
    });
  });
};

export const signOutUsers = (accessToken, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    AccessToken: accessToken /* required */,
  };
  return new Promise((resolve) => {
    identityProvider.globalSignOut(params, function (err, data) {
      if (err) {
        console.log(err, err.stack);
      } else {
        resolve(data);
      }
    });
  });
};

export const getUserRole = (paginationToken, username, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    UserPoolId: backOfficeUserPoolId,
    Username: username,
  };

  return new Promise((resolve) => {
    identityProvider.adminListGroupsForUser(params, async function (err, data) {
      if (err) {
        console.log(JSON.stringify(err));
      } else {
        await resolve(data);
      }
    });
  });
};

export const getBackOfficeUsers = (paginationToken, users = [], auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    PaginationToken: paginationToken,
    UserPoolId: backOfficeUserPoolId,
  };

  return new Promise((resolve) => {
    identityProvider.listUsers(params, async function (error, data) {
      if (error) {
        console.log(JSON.stringify(error));
      } else {
        const allUsers = users.concat(data.Users);
        const nextToken = data.PaginationToken;
        if (nextToken) {
          await resolve(getBackOfficeUsers(nextToken, allUsers, auth));
        } else {
          const userManagers = allUsers.map((user) => {
            return {
              organization: getValue(user, "custom:organization"),
              inspectorNumber: getValue(user, "custom:inspector_number"),
              sub: getValue(user, "sub"),
              username: user["Username"],
              email: getValue(user, "email"),
              firstName: getValue(user, "given_name"),
              lastName: getValue(user, "family_name"),
              enabled: user["Enabled"],
              status: user["UserStatus"],
            };
          });

          await resolve(userManagers);
        }
      }
    });
  });
};
export const getUserStatus = (username, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve) => {
    identityProvider.adminGetUser(params, function (error, data) {
      if (error) {
        console.log(JSON.stringify(error));
      } else {
        resolve(data.UserStatus);
      }
    });
  });
};

export const getUserSoundAttribute = (username, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve) => {
    identityProvider.adminGetUser(params, function (error, data) {
      if (error) {
        console.log(JSON.stringify(error));
      } else {
        resolve(getValueToken(data, "custom:sound_enable"));
      }
    });
  });
};

export const getNotificationsAttribute = (username, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve) => {
    identityProvider.adminGetUser(params, function (error, data) {
      if (error) {
        console.log(JSON.stringify(error));
      } else {
        resolve(getValueToken(data, "custom:notification_enable"));
      }
    });
  });
};

export const getUserDetails = (paginationToken, users = [], auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    PaginationToken: paginationToken,
    UserPoolId: backOfficeUserPoolId,
  };

  return new Promise((resolve) => {
    identityProvider.listUsers(params, async function (error, data) {
      if (error) {
        console.log(JSON.stringify(error));
      } else {
        const allUsers = users.concat(data.Users);
        const nextToken = data.PaginationToken;
        if (nextToken) {
          resolve(getUserDetails(nextToken, allUsers, auth));
        } else {
          const userDetails = allUsers.map((user) => {
            return {
              username: user["Username"],
              email: getValue(user, "email"),
            };
          });
          await resolve(userDetails);
        }
      }
    });
  });
};

export const getOneUserDetails = (username, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  const params = {
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve) => {
    identityProvider.adminGetUser(params, function (error, data) {
      if (error) {
        console.error(JSON.stringify(error));
      } else {
        resolve(data);
      }
    });
  });
};

export function updateAttributes(
  username,
  firstName,
  lastName,
  organization,
  auth
) {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    UserAttributes: [
      {
        Name: "given_name",
        Value: firstName,
      },
      {
        Name: "family_name",
        Value: lastName,
      },
      {
        Name: "custom:organization",
        Value: organization,
      },
    ],
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve, reject) => {
    identityProvider.adminUpdateUserAttributes(params, function (error, data) {
      if (error) reject(error);
      else resolve(data);
    });
  });
}
export function getTokenStatus(accessToken, auth) {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    AccessToken: accessToken,
  };
  return new Promise((resolve, reject) => {
    identityProvider.getUser(params, function (error, data) {
      if (error) reject(error);
      else {
        resolve(data);
      }
    });
  });
}

export function updateSoundAttribute(username, sound, auth) {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    UserAttributes: [
      {
        Name: "custom:sound_enable",
        Value: sound,
      },
    ],
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve, reject) => {
    identityProvider.adminUpdateUserAttributes(params, function (error, data) {
      if (error) reject(error);
      else {
        resolve(data);
      }
    });
  });
}

export function updateNotificationsAttribute(username, sound, auth) {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    UserAttributes: [
      {
        Name: "custom:notification_enable",
        Value: sound,
      },
    ],
    Username: username,
    UserPoolId: backOfficeUserPoolId,
  };
  return new Promise((resolve, reject) => {
    identityProvider.adminUpdateUserAttributes(params, function (error, data) {
      if (error) reject(error);
      else {
        resolve(data);
      }
    });
  });
}

export const changePassword = (password, username, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    UserPoolId: backOfficeUserPoolId,
    Username: username,
    Password: password,
    Permanent: true,
  };

  return new Promise((resolve, reject) => {
    identityProvider.adminSetUserPassword(params, function (error, data) {
      if (error) reject(error);
      else resolve(data);
    });
  });
};

const getValue = (cognitoUser, attributeName) => {
  const attribute = cognitoUser["Attributes"].find(
    (a) => a["Name"] === attributeName
  );
  return attribute ? attribute.Value : "";
};

export const getValueToken = (cognitoUser, attributeName) => {
  const attribute = cognitoUser["UserAttributes"].find(
    (a) => a["Name"] === attributeName
  );
  return attribute ? attribute.Value : "";
};

export function enableFrontOfficeUser(username, auth) {
  return enableUser(username, frontOfficeUserPoolId, auth);
}

export function disableFrontOfficeUser(username, auth) {
  return disableUser(username, frontOfficeUserPoolId, auth);
}

export function enableBackOfficeUser(username, auth) {
  return enableUser(username, backOfficeUserPoolId, auth);
}

export function disableBackOfficeUser(username, auth) {
  return disableUser(username, backOfficeUserPoolId, auth);
}

const enableUser = (username, userPoolId, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    UserPoolId: userPoolId,
    Username: username,
  };

  return new Promise((resolve, reject) => {
    identityProvider.adminEnableUser(params, async function (error, data) {
      if (error) reject(error);
      else await resolve(data);
    });
  });
};

const disableUser = (username, userPoolId, auth) => {
  const identityProvider = new AWS.CognitoIdentityServiceProvider(auth);
  var params = {
    UserPoolId: userPoolId,
    Username: username,
  };

  return new Promise((resolve, reject) => {
    identityProvider.adminDisableUser(params, async function (error, data) {
      if (error) reject(error);
      else await resolve(data);
    });
  });
};
