import React, { useState, useEffect } from "react";
import { makeStyles, Typography, IconButton } from "@material-ui/core";
import NotificationTemplate from "./NotificationTemplate";
import SandWatch from "../assets/images/sand_watch.png";

const useStyles = makeStyles((theme) => ({
    container: {
        zIndex: 5000,
        position: 'fixed',
        top: '60px',
        right: '15px',
    },
    arrow: {
        zIndex: 4999,
        position: 'absolute',
        width: '45px',
        height: '45px',
        backgroundColor: '#242a37',
        // transform: 'translateX(500px) translateY(-10px) rotate(45deg)',
        transform: 'rotate(45deg)',
        right: '5px',
        top: '-10px'
    },
    content: {
        zIndex: 5001,
        width: '600px',
        position: 'relative',
        borderRadius: '8px',
        right: '-10px'
    },
    header: {
        backgroundColor: '#242A37',
        height: '50px',
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        color: '#a5acc0',
        fontSize: '0.875rem',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textTransform: 'uppercase',
        padding: '10px 10px 10px 20px'
    },
    body: {
        maxHeight: '500px',
        overflow: 'auto',
        backgroundColor: '#ecf0f1',
    },
    footer: {
        backgroundColor: '#242a37',
        height: '50px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#a5acc0',
        '&:hover': {
            color: '#fff',
        }
    }
}));

export default function NotificationsPopUp(props) {
    const classes = useStyles();
    const { open, data } = props;
    const [cleanData, setCleanData] = useState([]);
    const [removedRowsArray, setRemovedRowsArray] = useState([]);
    const [expand, setExpand] = useState(false);

    // const handleDisplayedData = () => {
    //     if (displayedData) {
    //         if (displayedData.length > 3) setDisplayedData(data.slice(0, 3));
    //         else setDisplayedData(data);
    //     }
    // }

    useEffect(() => {
        // removing rows that were deleted by the user in the UI.
        setCleanData(data.filter(ele => !removedRowsArray.includes(ele.id)));
    }, [data, removedRowsArray])

    const removeRow = (row_id) => {
        setRemovedRowsArray(prev => prev.concat([row_id]));
    }

    const getDataImage = full_data_list => {
        // if 'reduce' is clicked in UI, then only 3 rows should be shown.
        return expand ? cleanData : cleanData.slice(0, 3);
    }

    return (
        <div className={classes.container} style={{ display: `${open ? 'block' : 'none'}` }}>
            <div className={classes.arrow}></div>
            <div className={classes.content}>
                <div className={classes.header}>
                    <span>
                        {'Notifications'}
                    </span>
                </div>
                <div className={classes.body}>
                    {(cleanData && cleanData.length > 0) ? (
                        getDataImage(cleanData).map((data, index) => <NotificationTemplate key={index} input={data} removeRow={removeRow} />)
                    ) : (
                        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img style={{ width: '100px', height: '100px' }} src={SandWatch} alt='Sand watch' />
                            <Typography style={{ color: '#4d4d4d', fontWeight: 'bold' }}>
                                {'There are no notification to display'}
                            </Typography>
                        </div>
                    )}
                </div>
                <div className={classes.footer}>
                    {(cleanData && cleanData.length > 3) && (
                        <IconButton style={{ width: '100%', height: '100%' }} color='inherit' onClick={() => setExpand(prev => !prev)}>
                            <Typography>
                                {getDataImage(cleanData).length > 3 ? 'Reduce' : 'Expand'}
                            </Typography>
                        </IconButton>
                    )}
                </div>
            </div>
        </div>
    )
}