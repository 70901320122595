import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  withAuthenticator,
  ConfirmSignIn as AmplifyConfirmSignIn,
  VerifyContact as AmplifyVerifyContact,
  ConfirmSignUp as AmplifyConfirmSignUp,
  // ForgotPassword as AmplifyForgotPassword,
  RequireNewPassword as AmplifyRequireNewPassword,
} from "aws-amplify-react";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import ResetPassword from "./auth/ResetPassword";
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from "./theme";
import Layout from "./common/Layout";
import "./App.css";
import axios from "axios";
import LoadingIndicator from "./common/LoadingIndicator";
import { AppProvider } from "./AppProvider";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Routes from "./common/Routes";
import { Auth } from "aws-amplify";

export const AuthTheme = {
  button: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "AgencyBold",
    fontSize: "20px",
    padding: "10px",
    borderRadius: "4px",
    "&hover": {
      background: "rgb(171, 52, 70)",
    },
  },
  a: { color: theme.palette.primary.main },
  input: {
    background: "transparent",
    border: "none",
    borderBottom: "1px solid rgba(255,255,255,0.5)",
    borderRadius: "0",
    color: "#fff",
    "&:focus": {
      outline: "none",
    },
  },
  formContainer: {
    margin: "0",
    marginTop: "200px",
  },
  sectionHeader: { color: "#f2f2f2" },
  formSection: {
    backgroundColor: "#242A37",
    borderRadius: "0px",
    fontFamily: theme.typography.fontFamily,
  },
  sectionHeaderContent: {
    fontSize: "28px",
    textAlign: "left",
    fontFamily: "AgencyBold",
    textTransform: "uppercase",
  },
  inputLabel: {
    color: "#7F8FA4",
    fontSize: "16px",
    fontFamily: "MuseoSans500",
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    // the actual interception
    axios.interceptors.request.use((req) => this.requestHandler(req));
    axios.interceptors.response.use(
      (res) => this.responseHandler(res),
      (err) => this.errorHandler(err)
    );
  }
  // the loadCount represents the number of active requests that didn't get any response yet
  state = {
    loadCount: 0,
    open: false,
    message: { text: "Unhandled operation", emoji: "✖" },
  };

  // Request and response handlers
  requestHandler = (request) => {
    this.setState({ loadCount: this.state.loadCount + 1 });
    Auth.currentUserCredentials().catch((error) => {
      console.log('Our log out error debug:', error);
      Auth.signOut().then(() => {
        this.props.onStateChange("signedOut");
      });
      window.location.reload();
    });
    // We're intercepting so we have to return what we are intercepting 😂
    return request;
  };
  responseHandler = (response) => {
    //verifying token status before getting any response

    if (
      response.config.method === "post" ||
      response.config.method === "put" ||
      response.config.method === "patch"
    ) {
      this.state.loadCount > 0 &&
        this.setState({
          message: {
            text: "Operation successful!",
            emoji: { symbol: "✔️", label: "Green check mark icon" },
          },
          open: true,
          loadCount: this.state.loadCount - 1,
        });
    }
    this.state.loadCount > 0 &&
      this.setState({ loadCount: this.state.loadCount - 1 });
    // We're intercepting so we have to return what we are intercepting
    return response;
  };
  // We're intercepting so we have to return what we are intercepting
  errorHandler = (error) => {
    if (
      error.response &&
      (error.response.data.errors[0].code === "PLANNING_SESSION_STARTED" ||
        error.response.data.errors[0].code === "PLANNING_SESSION_NOT_EMPTY" ||
        error.response.data.errors[0].code === "CLASS_SESSION_STARTED" ||
        error.response.data.errors[0].code === "INVALID_DELETE" ||
        error.response.data.errors[0].code === "LICENCE_DOES_NOT_EXIST" ||
        error.response.data.errors[0].code === "TRACKER_DOES_NOT_EXIST" ||
        error.response.data.errors[0].code === "INVALID_REQUEST_COMPETITOR_IN_RACE")
    ) {
      this.state.loadCount > 0 &&
        this.setState({
          message: {
            text: error.response.data.errors[0].detail,
            emoji: { symbol: "❌", label: "red cross icon" },
          },
          open: false,
          loadCount: this.state.loadCount - 1,
        });
      return error;
    } else {
      this.state.loadCount > 0 &&
        this.setState({
          message: {
            text: "Something is wrong!",
            emoji: { symbol: "❌", label: "red cross icon" },
          },
          open: true,
          loadCount: this.state.loadCount - 1,
        });
      // We're intercepting so we have to return what we are intercepting
      return error;
    }
  };
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setOpen(false);
  };
  setOpen = (s) => {
    this.setState({ open: s });
  };

  render() {
    return (
      <AppProvider>
        {/* the loadCount represents the number of active requests that didn't get any response yet */}
        <LoadingIndicator loadCount={this.state.loadCount} />
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            {/* the loadCount represents the number of active requests that didn't get any response yet */}
            <LoadingIndicator loadCount={this.state.loadCount} />
            {/* GLOBAL ERROR HANDLING */}
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={this.state.open}
              autoHideDuration={6000}
              onClose={this.handleClose}
              message={
                <span>
                  {this.state.message.text}{" "}
                  <span role="img" aria-label={this.state.message.emoji.label}>
                    {this.state.message.emoji.symbol}
                  </span>
                </span>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  style={{ padding: theme.spacing(0.5) }}
                  onClick={this.handleClose}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
            />
            <Layout>
              <Routes />
            </Layout>
          </MuiThemeProvider>
        </BrowserRouter>
      </AppProvider>
    );
  }
}

export default withAuthenticator(
  App,
  false,
  [
    <SignUp />,
    <SignIn />,
    <AmplifyConfirmSignIn />,
    <AmplifyVerifyContact />,
    <AmplifyConfirmSignUp />,
    <ResetPassword />,
    // <AmplifyForgotPassword />,
    <AmplifyRequireNewPassword />,
  ],
  null,
  AuthTheme
);
