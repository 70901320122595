import React, { Component } from "react";
import { setCurrentLanguage, getCurrentLanguage } from "./common/language";
import { Auth } from "aws-amplify";
import { config } from "./config";
import { matchPath } from "react-router-dom";

// Create new context
export const AppContext = React.createContext();

// Then create a Provider Component
export class AppProvider extends Component {
  state = {
    language: getCurrentLanguage()
  };

  changeLanguage = lang => {
    this.setState(
      { language: lang }, //updater
      () => {
        setCurrentLanguage(this.state.language);
      } // callback
    );
  };

  hasDrawer = () => {
    let result = true;
    const userRoles = JSON.parse(
      atob(Auth.user.signInUserSession.accessToken.jwtToken.split(".")[1])
    );
    if (userRoles["cognito:groups"]) {
      for (let i = 0; i < userRoles["cognito:groups"].length; i++) {
        const found = config["roles"].find(
          element => element.role === userRoles["cognito:groups"][i]
        );
        if (found) {
          result =
            !(found["control"]["functionalities"].length === 1 &&
            found["control"]["functionalities"][0] === "nct_gps");
        }
      }
    }
    return result
  };

  accessible = item => {
    let result = false;
    const userRoles = JSON.parse(
      atob(Auth.user.signInUserSession.accessToken.jwtToken.split(".")[1])
    );
    if (userRoles["cognito:groups"]) {
      for (let i = 0; i < userRoles["cognito:groups"].length; i++) {
        const found = config["roles"].find(
          element => element.role === userRoles["cognito:groups"][i]
        );
        if (found) {
          if (item.includes("/")) {
            const paramRoutes = found["control"]["routes"].filter(element =>
              element.includes("/:")
            );
            const matchProfile = matchPath(item, {
              path: paramRoutes
            });
            result = matchProfile
              ? true
              : found["control"]["routes"].includes(item);
          } else {
            result = found["control"]["functionalities"].includes(item);
          }
          if (result) break;
        }
      }
    }
    return result;
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          changeLanguage: this.changeLanguage,
          accessible: this.accessible,
          hasDrawer: this.hasDrawer
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
