import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
}));

export default function NavBar(props) {
  const { context } = props;
  const [value, setValue] = useState("0");
  const classes = useStyles();

  const getCurrentValue = useCallback(() => {
    let path = window.location.pathname;
    return path === "/gps/nct" ? "1" : path.includes("/gps/timing") ? "2" : path.includes("/esm") ? "3" : "0";
  }, []);

  useEffect(() => {
    setValue(getCurrentValue());
  }, [getCurrentValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        color="transparent"
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="action tabs example"
      >
        {context.accessible("rcs_home") && (
          <Tab label="RCS Home" component={Link} to="/" value="0"/>
        )}
        {context.accessible("nct_gps") && (
          <Tab label="GPS" component={Link} to="/gps/nct" value="1"/>
        )}
        {context.accessible("timing") && (
          <Tab label="Timing" component={Link} to="/gps/timing" value="2"/>
        )}
        {context.accessible("esm") && (
          <Tab label="ESM" component={Link} to="/esm" value="3"/>
        )}
      </Tabs>
    </div>
  );
}
