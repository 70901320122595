import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  overrides: {
    
   MuiExpansionPanel: {
    root: {
      "&:before": {
        backgroundColor: "#f1f1f1"
      }
    }
   },
    MuiInput: {
      root: {
        paddingLeft: 3
      }
    },
    MuiInputLabel: {
      root: {
        paddingLeft: 3
      }
    },

    MuiCard: {
      root: {
        boxShadow: "0px 1px 1px 0 rgba(0,0,0,0.12)",
      }
    },
    MuiCardActionArea: {
      root: {
        borderRadius: 4,
        height: "100%"
      }
    },
    MuiPaper: {
      root: {
        boxShadow: "0px 1px 1px 0 rgba(0,0,0,0.12)"
      }
    },
    MuiCardMedia: {
      root: {
        marginTop: 0
      }
    },
    MuiCardContent: {
      root: {
        paddingTop: 10
      }
    },
    MuiTypography: {
      root: {
        fontFamily: "MuseoSans300",
        fontSize: "1rem"
      },
      body1: {
        fontFamily: "MuseoSans300"
      },
      body2: {
        fontFamily: "MuseoSans300"
      },
      h1: {
        fontSize: "1.75rem",
        fontFamily: "AgencyBold, sans-serif",
        textTransform: "uppercase"
      },
      h3: {
        fontSize: "1.15rem",
        fontFamily: "AgencyBold, sans-serif",
        margin: "1%"
      },
      h2: {
        fontSize: "1.25rem",
        fontFamily: "AgencyBold, sans-serif",
        textTransform: "uppercase"
      },
      h5: {
        fontSize: "1rem",
        fontFamily: "AgencyBold, sans-serif"
      },
      h6: {
        fontSize: ".9rem",
        fontFamily: "MuseoSans300"
      }
    },
    MuiTable: {
      stickyHeader: {
        borderCollapse: "collapse"
      }
    },
    MuiTableHead: {
      root: {
        color: "#8790ab",
        textTransform: "uppercase"
      }
    },
    MuiTableSortLabel: {
      root: {
        color: "#8790ab",
        textTransform: "uppercase",
        fontWeight: 800
      }
    },
    MuiTableRow: {
      root: {
        minHeight: 74,
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f3f4f7 !important"
        },
        "&$selected, &$selected:hover": {
          borderLeft: "3px solid #f54b64"
        }
      }
    },
    MuiTableCell: {
      root: {
        fontSize: "0.9rem",
        borderBottom: "1px solid #c3c8d5"
      }
    },
    MuiTextField: {
      root: {
        "& ::before": {
          borderBottomColor: "#ddd"
        },
        "& input": {
          color: "#999",
          borderBottomColor: "#666",
          fontFamily: "MuseoSans300",
          "&:disabled": {
            opacity: 0.5,
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "5px"
          }
        }
      }
    },
    MuiButton: {
      root: {
        borderRadius: 2.3,
        fontFamily: "AgencyBold",
        "&:hover": {
          background: "#fa667c",
          textDecoration: "none"
        },
        "&:disabled": {
          color: "#6d7294",
          background: "#d8dde6"
        }
      },
      contained: {
        "&:disabled": {
          color: "#6d7294",
          background: "#d8dde6"
        }
      }
    },

    MuiLink: {
      root: {
        fontFamily: "MuseoSans300"
      }
    },

    MuiListItemText: {
      primary: {
        fontSize: "13px"
      }
    },
    MuiDivider: {
      root: {
        marginTop: 14,
        marginBottom: 14
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: "#F9FAFC",
        border: ".7px solid #B3B7C94D"
      }
    },
    MuiStepIcon: {
      root: {
        fontSize: "2rem",
        color: "#808599"
      }
    },
    MuiStepLabel: {
      root: {
        color: "#808599"
      }
    },
    MuiStepConnector: {
      line: {
        borderWidth: 0.5,
        borderColor: "#dedede"
      }
    },
    MuiIconButton: {
      root: {
        height: "100%",
        display: "inline-block",
        color: "#2c3d61",
        borderRadius: "none"
      }
    }
  },

  palette: {
    primary: {
      main: "#f54b64"
    },
    secondary: {
      main: "#2c3d61"
    },
    break: {
      main: "#d3dae8"
    },
    text: {
      primary: "#3c475d",
      secondary: "#a5acc0"
    },
    divider: "#323b4e",
    background: {
      default: "#f3f4f7",
      paper: "#fff"
    }
  }
});
