import { withRouter, Switch, Route } from "react-router";
import { lazy, Suspense } from "react";
import React from "react";
import LoadingIndicator from "./LoadingIndicator";
import { Redirect } from "react-router-dom";
import { AppContext } from "../AppProvider";

const Profile = withRouter(
  lazy(() => import(/* webpackChunkName: "profile" */ "../user/Profile"))
);

const Services = withRouter(
  lazy(() => import(/* webpackChunkName: "services" */ "../user/Services"))
);

const Users = withRouter(
  lazy(() => import(/* webpackChunkName: "users" */ "../users/Users"))
);

const AddUser = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "AddUser" */ "../users/user-creation/CreateUser"
    )
  )
);

const Events = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "Events" */ "../events/EventsDashboard")
  )
);

const EventDetails = withRouter(
  lazy(() => import(/* webpackChunkName: "EventDetails" */ "../events/Details"))
);

const CreateEvents = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "CreateEvents" */ "../events/CreateEventStepper"
    )
  )
);

const TemplateDetails = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "TemplateDetails" */ "../template/TemplateDetails"
    )
  )
);

const TeamManagers = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "TeamManagers" */ "../competitors/teamManagers/TeamManagers"
    )
  )
);

const Notifications = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Notifications" */ "../competitors/notifications/Notifications"
    )
  )
);
const ApprovedCompetitors = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Notifications" */ "../approvedCompetitors/ApprovedCompetitors"
    )
  )
);

const Machines = withRouter(
  lazy(() => import(/* webpackChunkName: "Machines" */ "../machines/Machines"))
);

const mapGps = withRouter(
  lazy(() => import(/* webpackChunkName: "TeamManagers" */ "../map/MapLayout"))
);

const Esm = withRouter(
  lazy(() => import(/* webpackChunkName: "ESM" */ "../esm/LandingPage"))
);

const Briefing = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "Briefing" */ "../esm/briefing/Briefing")
  )
);

const TXEligibility = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Briefing" */ "../esm/txEligibility/TXEligibility"
    )
  )
);

const ClerkOfCourse = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "ClerkOfCourse" */ "../esm/clerkOfCourse/ClerkOfCourse"
    )
  )
);

const TXEligibilityManual = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Briefing" */ "../esm/txEligibilityManual/TXEligibilityManual"
    )
  )
);

const ClothingInspection = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Briefing" */ "../esm/clothingInspection/ClothingInspection"
    )
  )
);

const SigningOn = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "signing on" */ "../esm/signingOn/SigningOn"
    )
  )
);

const HelmetInspection = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "helmet inspection" */ "../esm/helmetInspection/HelmetInspection"
    )
  )
);

const TransponderAssignment = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "transponder assignment" */ "../esm/transponderAssignment/TransponderAssignment"
    )
  )
);

const TechnicalInspection = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "technical inspection" */ "../esm/technicalInspection/TechnicalInspection"
    )
  )
);

const MedicalStops = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "medical stops" */ "../esm/medicalStops/MedicalStops"
    )
  )
);

const timingMap = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "TeamManagers" */ "../timingSystem/TimingSystemLayout"
    )
  )
);

const ManageTrackers = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "ManageTrackers" */ "../vehicles/trackers/ManageTrackers"
    )
  )
);

const GpsTrackers = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "GpsTrackers" */ "../vehicles/gps/GpsTrackers")
  )
);

const rulesConfiguration = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "RulesConfiguration" */ "../events/rulesConfiguration/RulesConfiguration"
    )
  )
);

const raceResults = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "RaceResults" */ "../raceResults/ResultsDashboard"
    )
  )
);

const gridFormation = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "GridFormation" */ "../gridFormation/GridFormation"
    )
  )
);

const paddockRegistration = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "Registration" */ "../Paddock/Paddock")
  )
);

const Participant = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "EventParticipant" */ "../participants/EventParticipant"
    )
  )
);

const NewRegistration = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "new registration" */ "../participants/newRegistration/NewRegistration"
    )
  )
);

const RegistrationDetails = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "registration details" */ "../participants/registrationDetails/RegistrationDetails"
    )
  )
);

const Details = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "Details" */ "../Paddock/Details/Details")
  )
);

const OnTrack = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "OnTrack" */ "../onTrackEvents/onTrack/OnTrack")
  )
);

const OnTrackReadOnly = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "OnTrackReadOnly" */ "../onTrackEvents/onTrackReadOnly/OnTrackReadOnly"
    )
  )
);

const Retirements = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Retirements" */ "../onTrackEvents/retirements/Retirements"
    )
  )
);

const PredictedPosition = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "PredictedPosition" */ "../onTrackEvents/predictedPosition/PredictedPosition"
    )
  )
);

const EventLogEntriesStreaming = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "EventLog" */ "../eventLog/eventLogStreaming/EventLogEntriesStrearming"
    )
  )
);

const EventLog = withRouter(
  lazy(() => import(/* webpackChunkName: "EventLog" */ "../eventLog/EventLog"))
);

const EsmScheduling = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "EsmScheduling" */ "../scheduleESM/esmScheduling"
    )
  )
);

const NewEventLogEntry = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "EventLog" */ "../eventLog/NewEventLogEntry")
  )
);

const EventLogEntries = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "EventLog" */ "../eventLog/EventLogEntries")
  )
);

const Session = withRouter(
  lazy(() => import(/* webpackChunkName: "Session" */ "../session/Session"))
);

const NewSession = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "NewSession" */ "../session/session-creation/CreateSession"
    )
  )
);

const EditSession = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "NewSession" */ "../session/session-edition/EditSession"
    )
  )
);

const Penalties = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "Penalties" */ "../penalties/Penalties")
  )
);

const StartTime = withRouter(
  lazy(() =>
    import(/* webpackChunkName: "StartTime" */ "../startTime/StartTime.jsx")
  )
);

const Laps = withRouter(
  lazy(() => import(/* webpackChunkName: "laps" */ "../laps/Laps"))
);

const Qualification = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "Qualification" */ "../qualification/Qualification"
    )
  )
);

const Passings = withRouter(
  lazy(() => import(/* webpackChunkName: "Passings" */ "../passings/Passings"))
);

const Sectors = withRouter(
  lazy(() => import(/* webpackChunkName: "Sectors" */ "../sectors/Sectors"))
);

const ControlBoard = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "ControlBoard" */ "../controlBoard/ControlBoard"
    )
  )
);

const BrandedReports = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "BrandedReports" */ "../brandedReports/BrandedReports"
    )
  )
);

const NewRetirementLogs = withRouter(
  lazy(() =>
    import(
      /* webpackChunkName: "New Retirement Logs" */ "../retirementLogs/NewRetirementLogs"
    )
  )
);

const RetirementLogs = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "Retirement Logs" */ "../retirementLogs/RetirementLogs"
    )
  )
);

const Documentation = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "Documentation" */ "../documentation/Documentation"
    )
  )
);

const TsCards = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "TsCards" */ "../tsCards/TsCards"
    )
  )
);

const MachinesTransponderTracker = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "Machines transponder tracker" */ "../eventDashboard/machinesTransponderTracker/MachinesTransponderTracker"
    )
  )
);

const StatusCompetitorStops = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "status/competitor stops" */ "../eventDashboard/statusCompetitorStops/StatusCompetitorStops"
    )
  )
);

const Bulletin = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "status/Bulletin */ "../bulletin/Bulletin"
    )
  )
);

const NewBulletin = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "status/add bulletin" */ "../bulletin/AddBulletin"
    )
  )
);

const BulletinReadOnly = withRouter(
  lazy(() =>
    import(
      /* WebpackChunkName: "status/bulletin read only" */ "../bulletin/bulletinReadOnly/BulletinReadOnly"
    )
  )
);

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <AppContext.Consumer>
      {(context) => (
        <Route
          {...rest}
          render={(props) => {
            return context.accessible(props.location.pathname) ? (
              <Component {...props} />
            ) : (
              <Redirect to="/" />
            );
          }}
        />
      )}
    </AppContext.Consumer>
  );
};

export default function Routes(props) {
  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Switch>
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/services" component={Services} />
        <PrivateRoute path="/events" exact component={Events} />
        <PrivateRoute path="/events/new" exact component={CreateEvents} />
        <PrivateRoute path="/event/:id" exact component={EventDetails} />
        <PrivateRoute path="/template" exact component={TemplateDetails} />
        <PrivateRoute path="/users" exact component={Users} />
        <PrivateRoute path="/users/new" exact component={AddUser} />
        <PrivateRoute path="/team-managers" exact component={TeamManagers} />
        <PrivateRoute path="/notifications" exact component={Notifications} />
        <PrivateRoute path="/machines" exact component={Machines} />
        <PrivateRoute path="/gps/nct" exact component={mapGps} />
        <PrivateRoute path="/esm" exact component={Esm} />
        <PrivateRoute path="/esm/briefing" exact component={Briefing} />
        <PrivateRoute
          path="/esm/tx-eligibility"
          exact
          component={TXEligibility}
        />
        <PrivateRoute
          path="/esm/transponder_assignment"
          exact
          component={TransponderAssignment}
        />
        <PrivateRoute
          path="/esm/tx-eligibility-manual"
          exact
          component={TXEligibilityManual}
        />
        <PrivateRoute
          path="/esm/clothing-inspection"
          exact
          component={ClothingInspection}
        />
        <PrivateRoute
          path="/esm/technical_inspection"
          exact
          component={TechnicalInspection}
        />
        <PrivateRoute
          path="/esm/clerk-of-course"
          exact
          component={ClerkOfCourse}
        />
        <PrivateRoute
          path="/esm/signing_on"
          exact
          component={SigningOn}
        />
        <PrivateRoute
          path="/esm/helmet_inspection"
          exact
          component={HelmetInspection}
        />
        <PrivateRoute
          path="/esm/medical-stops"
          exact
          component={MedicalStops}
        />
        <PrivateRoute path="/gps/timing" exact component={timingMap} />
        <PrivateRoute
          path="/machines/trackers"
          exact
          component={ManageTrackers}
        />
        <PrivateRoute
          path="/machines/gpstrackers"
          exact
          component={GpsTrackers}
        />
        <PrivateRoute path="/registrations" exact component={Participant} />
        <PrivateRoute
          path="/events/rulesconfiguration"
          exact
          component={rulesConfiguration}
        />
        <PrivateRoute path="/race-results" exact component={raceResults} />
        <PrivateRoute path="/grid-formation" exact component={gridFormation} />
        <PrivateRoute path="/paddock" exact component={paddockRegistration} />
        <PrivateRoute
          path="/paddock/details/:id1/:id2"
          exact
          component={Details}
        />
        <PrivateRoute path="/on-track" exact component={OnTrack} />
        <PrivateRoute
          path="/on-track-read-only"
          exact
          component={OnTrackReadOnly}
        />
        <PrivateRoute path="/retirements" exact component={Retirements} />
        <PrivateRoute
          path="/predicted_position"
          exact
          component={PredictedPosition}
        />
        <PrivateRoute path="/event-log" exact component={EventLog} />
        <PrivateRoute
          path="/event-log-ro"
          exact
          component={EventLogEntriesStreaming}
        />
        <PrivateRoute path="/esm-scheduling" exact component={EsmScheduling} />
        <PrivateRoute
          path="/event-log-entries/new"
          exact
          component={NewEventLogEntry}
        />
        <PrivateRoute
          path="/event-log-entries"
          exact
          component={EventLogEntries}
        />
        <PrivateRoute
          path="/registrations/new"
          exact
          component={NewRegistration}
        />
        <PrivateRoute
          path="/registrations/details"
          exact
          component={RegistrationDetails}
        />
        <PrivateRoute path="/sessions" exact component={Session} />
        <PrivateRoute path="/session/new" exact component={NewSession} />
        <PrivateRoute path="/session/Edit" exact component={EditSession} />
        <PrivateRoute path="/penalties" exact component={Penalties} />
        <PrivateRoute path="/laps" exact component={Laps} />
        <PrivateRoute
          path="/qualification-dashboard"
          exact
          component={Qualification}
        />
        <PrivateRoute path="/start-time" exact component={StartTime} />
        <PrivateRoute path="/passings" exact component={Passings} />
        <PrivateRoute path="/sectors" exact component={Sectors} />
        <PrivateRoute path="/control-board" exact component={ControlBoard} />
        <PrivateRoute path="/documentation" exact component={Documentation} />
        <PrivateRoute
          path="/gps/timing/qualification/event/:event_id/:event_name/class/:class_id/:class_name"
          exact
          component={timingMap}
        />
        <PrivateRoute
          path="/gps/timing/grid/session/:session_id/:session_name/:event/:class/:plate_color/:number_color/:day"
          exact
          component={timingMap}
        />
        <PrivateRoute
          path="/gps/timing/results/session/:session_id/:session_name/:event/:class/:day"
          exact
          component={timingMap}
        />
        <PrivateRoute
          path="/branded-reports"
          exact
          component={BrandedReports}
        />
        <PrivateRoute
          path="/retirement-logs/new"
          exact
          component={NewRetirementLogs}
        />
        <PrivateRoute
          path="/retirement-logs"
          exact
          component={RetirementLogs}
        />
        <PrivateRoute
          path="/approved-competitors"
          exact
          component={ApprovedCompetitors}
        />
        <PrivateRoute
          path="/ts-cards"
          exact
          component={TsCards}
        />
        <PrivateRoute
          path="/status-competitor-stops"
          exact
          component={StatusCompetitorStops}
        />
        <PrivateRoute
          path="/machines-tracker-transponder"
          exact
          component={MachinesTransponderTracker}
        />
        <PrivateRoute
          path="/bulletin"
          exact
          component={Bulletin}
        />
        <PrivateRoute
          path="/bulletin/new"
          exact
          component={NewBulletin}
        />
        <PrivateRoute
          path="/bulletin-read-only"
          exact
          component={BulletinReadOnly}
        />
      </Switch>
    </Suspense>
  );
}