import React, { forwardRef, useEffect, useState } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  MenuItem,
  Divider,
  // Badge,
  Menu,
  ButtonGroup,
  Button,
  Typography
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsIcon from '@material-ui/icons/NotificationsActive';
// import NotificationsIcon from "@material-ui/icons/Notifications";
import { Auth } from "aws-amplify";
import { NavLink, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import MainMenu from "./MainMenu";
import AppTitle from "./AppTitle";
import { AppContext } from "../AppProvider";
import { config } from "../config";
import Translate from "./Translate";
// import BtnLink from "./BtnLink";
import NavBar from "./NavBar";
import Footer from "./Footer";
import NotificationsPopUp from "../manualPenaltiesNotifications/NotificationsPopUp";
import { getUserRole, getNotificationsAttribute } from "../common/cognito-services";
import { sign } from "aws4";

const drawerWidth = 280;
const appBarHeight = "auto";
const menuButtonColor = "#fff";
const appBarBackground = "#242A37";
const notificationPermittedRoles = ['TA', 'CTK', "RA", "COC", "ESO", "CMO", "POLICE", "FIRE", "AMBULANCE", "TC", "CC", "LSD", "GPSAPI", "MEDIA", "TTO", "MGPO", "ROS", "SCOUTS", "MT",
"PDM", "TO", "TT", "TTPARADE", "CTT", "CTTPARADE", "MGP", "MGPPARADE", "OTHERS", "TTC", "ELA", "PO", "RETO", "ACURET", "CTO", "WO", "OTRO"];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appTitle: {
    fontSize: ".95rem",
  },
  appBar: {
    backgroundColor: "#242A37",
    height: appBarHeight,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  noShift: {
    marginLeft: 20,
  },
  menuButton: {
    padding: 0,
    borderRadius: "none",
    width: "100%",
    height: "100%",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    // zIndex: 9999,
  },
  drawerPaper: {
    backgroundColor: "#242A37",
    width: drawerWidth,
  },
  drawerHeader: {
    backgroundColor: "#242A37",
    color: "#fff",
    padding: 0,
  },
  button: {
    textDecoration: "none",
    color: "unset",
  },

  content: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  gpsContent: {
    marginTop: 0,
    width: "100%",
    // height: "100%",
    padding: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },

  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },

  langSwitcher: {
    padding: "9px 0 9px 11px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  langMenu: {
    "&:hover": {
      backgroundColor: "unset",
      cursor: "initial",
    },
  },
  fontMenu: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    "&:hover": {
      backgroundColor: "unset",
    },
  },
  toolbar: {
    height: appBarHeight,
    // display: "grid",
    // gridTemplateColumns: "1fr 5rem 1fr 10rem",
    background: appBarBackground,
  },
  container: {
    marginTop: theme.spacing(4),
    paddingRight: theme.spacing(0),
    paddingBottom: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  mainMenu: {
    backgroundColor: "#242A37",
    color: '#878ea0'
  },
  username: {
    fontFamily: "ProximaBold",
    textAlign: "center",
    color: "#e1e3ea"
  },
}));

const NavMenuItem = forwardRef((props, ref) => {
  return (
    <MenuItem
      component={NavLink}
      to={{ pathname: props.route, search: window.location.search }}
      onClick={props.onClick}
      classes={props.classes}
    >
      {props.children}
    </MenuItem>
  );
});

export default function Layout(props) {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState();
  const openUserMenu = Boolean(anchorEl);
  const [customFontSize, setCustomFontSize] = React.useState(null);
  // const [windowSize, setWindowSize] = React.useState();
  const [openLeftDrawer, setOpenLeftDrawer] = React.useState(false)
  const [openNotifications, setOpenNotifications] = useState(false);
  const [credentials, setCredentials] = useState();
  const [role, setRole] = useState();
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  // const [ticker, setTicker] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);

  const notificationsRoleAccess = rolesArray => {
    let auxAccess = false;
    let i = 0;
    while (i < rolesArray.length && !auxAccess) {
      if (notificationPermittedRoles.includes(rolesArray[i])) auxAccess = true;
      ++i;
    }
    return auxAccess;
  }

  var webSocketConnect = async function () {
    return new Promise(async (resolve, reject) => {
      var c = await Auth.currentUserCredentials();
      var credentials = Auth.essentialCredentials(c);
      var accessKeyId = credentials.accessKeyId;
      var secretAccessKey = credentials.secretAccessKey;
      var sessionToken = credentials.sessionToken;
      const AWS_REGION = config.eventLogStreamingWebsocket.region; // The region of your API-gateway
      const ENV = config.eventLogStreamingWebsocket.env; // The stage of your target deployment
      const WEBSOCKET_URL = config.penaltiesNotificationsWebsocket.endpoint; // Don't prepend with wss!
      const result = await sign(
        {
          host: WEBSOCKET_URL,
          path: `/${ENV}?X-Amz-Security-Token=${encodeURIComponent(
            sessionToken
          )}`,
          service: "execute-api",
          region: AWS_REGION,
          signQuery: true,
        },
        {
          accessKeyId,
          secretAccessKey,
        }
      );
      resolve(new WebSocket(`wss://${WEBSOCKET_URL}${result.path}`));
    });
  };

  useEffect(() => {
    if (role && role.length > 0 && notificationsRoleAccess(role) && notificationsEnabled) {
      // In this useEffect, there is the websocket from which we will receive the event log entries data streaming
      var ws = null
      var intervalID = null;

      async function connect() {
        ws = await webSocketConnect();

        function keepAlive() {
          console.log("keep alive");
          try {
            ws.send(
              JSON.stringify({
                action: "keepAlive",
              })
            );
          } catch (e) {
            console.log(e);
          }
        }
        ws.onopen = () => {
          try {
            ws.send(
              JSON.stringify(
                {
                  action: "penaltiesNotification",
                })
            );
            intervalID = window.setInterval(keepAlive, 180000);
          } catch (e) {
            console.error('Error: This is a custom warning: An error was catched while opening the on track websocket.');
          }
          let socketData = [];
          ws.onmessage = async (event) => {
            try {
              const message = JSON.parse(event.data);
              if (message?.action !== "keepAlive" && message?.message !== "Forbidden") {
                console.log('message:', message);
                // const duplicateTest = socketData.findIndex(ele => ele.id === message.id);
                // if (duplicateTest === -1) {
                //   // If the data is new, push it in the data array.
                //   socketData = [message].concat(socketData);
                // }
                // else {
                //   // If the received data is an update for an old data, overwrite it in the right spot.
                //   socketData[duplicateTest] = message;
                // }
                socketData = [message].concat(socketData);
                setNotificationsData(socketData);
                setAnchorEl(null);
                setOpenNotifications(true);
              }
            } catch (e) {
              console.error(e.toString());
            }
          };
          ws.onclose = () => {
            connect()
          };
          return () => {
            if (ws) {
              ws.onclose = () => { };
              ws.close()
            }
            clearInterval(intervalID)
          };
        }
      }
      connect();
    }
  }, [role, notificationsEnabled]);

  useEffect(() => {
    Auth.currentUserCredentials().catch((error) => {
      Auth.signOut();
    });
  }, []);

  // const updateWindowDimensions = () => {
  //   setWindowSize(window.innerWidth);
  // };

  // useEffect(() => {
  //   updateWindowDimensions();
  //   window.addEventListener("resize", updateWindowDimensions);
  //   return () => {
  //     window.removeEventListener("resize", updateWindowDimensions);
  //   };
  // }, []);

  useEffect(() => {
    Auth.currentUserPoolUser().then((res) => {
      setState(res.attributes);
    });
  }, []);

  useEffect(() => {
    Auth.currentUserCredentials().then((res) => {
      setCredentials(
        {
          region: config.cognito.backOfficeUserPool.region,
          credentials: Auth.essentialCredentials(res),
        }
      );
    });
  }, []);

  useEffect(() => {
    if (credentials) {
      Auth.currentUserPoolUser()
        .then(res => getUserRole(null, res.username, credentials))
        .then(res => {
          setRole(res.Groups.map(val => val.GroupName));
        });
    }
  }, [credentials]);

  // useEffect(() => {
  //   // this useEffect manage the dependency of the useEffect containing getUserSoundAttribute to limit the it's execution (make it each 10 seconds) to reduce the cognito api call costs.
  //   const interval = setInterval(() => setTicker(prev => !prev), 3000);
  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if (credentials) {
      Auth.currentUserPoolUser().then((res) => {
        getNotificationsAttribute(res.username, credentials).then((data) => {
          data === "active" ? setNotificationsEnabled(true) : setNotificationsEnabled(false);
        });
      });
    }
  }, [credentials]);

  // const handleDrawerOpen = () => {
  //   setOpen(true);
  // };

  const handleMenu = (event) => {
    setOpenNotifications(false);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeFontSize = (event, sizeLabel) => {
    const size = config.fontSize[sizeLabel];
    setCustomFontSize(size);
  };

  let isNotGpsModule = !useRouteMatch("/gps");
  let isNotEsmModule = !useRouteMatch("/esm");


  return (
    <AppContext.Consumer>
      {(context) => (
        <div className={classes.root}>
          <CssBaseline />
          {customFontSize && (
            <style jsx="true">{`
              *
                :not(#app-title-open):not(#app-title-close):not(#burger):not(#burger-closed):not(.mainMenu):not(h1):not(.icon-ntt) {
                font-size: ${customFontSize};
              }
            `}</style>
          )}
          <AppBar
            className={`${classes.appBar} ${(isNotGpsModule && context.hasDrawer() && openLeftDrawer
              ? classes.appBarShift
              : classes.noShift)
              }`}
          >
            <Toolbar className={classes.toolbar} disableGutters variant="dense">
              {isNotGpsModule && isNotEsmModule && context.hasDrawer() && !openLeftDrawer &&
                <div style={{ display: 'inline-block', width: 80, paddingLeft: 10 }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={() => setOpenLeftDrawer(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                </div>
              }

              <NavBar context={context} />

              <div style={{ display: 'inline-block', width: 60 }}>
                <Button
                  target="_blank"
                  to="/help"
                  variant="contained"
                  color="default"
                  style={{ marginLeft: 20 }}
                >
                  <a
                    className={classes.button}
                    href={config.quickSight.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Reporting
                  </a>
                </Button>
                {/* <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  className={clsx(classes.menuButton, {
                    [classes.hide]: open,
                  })}
                >
                  <MenuIcon id="burger-closed" />
                </IconButton> */}
              </div>
              <div style={{ margin: "auto auto auto 15px" }}>
              </div>


              <div>

              </div>
              <div style={role && role.length > 0 && notificationsRoleAccess(role) && notificationsEnabled ? null : { paddingRight: 20 }}>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircleIcon size="small" />
                  <Typography
                    id="username"
                    className={classes.username}
                  >
                    {state && state.given_name} {state && state.family_name}
                  </Typography>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openUserMenu}
                  onClose={handleClose}
                  className={classes.menu}
                >
                  <NavMenuItem route="/profile" onClick={handleClose}>
                    <div>
                      <div style={{ fontWeight: "bold" }}>
                        {state && state.given_name} {state && state.family_name}
                      </div>
                      <div>{state && state.email}</div>
                    </div>
                  </NavMenuItem>
                  <Divider />

                  <NavMenuItem route="/services" onClick={handleClose}>
                    <Translate i18nKey="my_services" />
                  </NavMenuItem>
                  <MenuItem
                    aria-label="change language"
                    aria-controls="menu-lang"
                    aria-haspopup="true"
                    color="inherit"
                    className={classes.langMenu}
                  >
                    <Translate i18nKey="change_text_size" />
                  </MenuItem>
                  <MenuItem className={classes.fontMenu}>
                    <ButtonGroup
                      color="secondary"
                      aria-label="outlined primary button group"
                    >
                      <Button
                        onClick={(event) => changeFontSize(event, "small")}
                      >
                        SM
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "medium")}
                      >
                        MD
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "large")}
                      >
                        LG
                      </Button>
                      <Button
                        onClick={(event) => changeFontSize(event, "xlarge")}
                      >
                        XL
                      </Button>
                    </ButtonGroup>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => Auth.signOut()}>
                    <Translate i18nKey="sign_out" />
                  </MenuItem>
                </Menu>
              </div>
              {role && role.length > 0 && notificationsRoleAccess(role) && notificationsEnabled &&
                <div style={{ paddingRight: 20 }}>
                  <IconButton color='inherit' onClick={() => setOpenNotifications(prev => !prev)}>
                    <NotificationsIcon size="small" style={{ color: `${openNotifications ? '#f54b56' : '#fff'}` }} />
                  </IconButton>
                </div>}
            </Toolbar>
          </AppBar>
          {role && role.length > 0 && notificationsRoleAccess(role) && notificationsEnabled &&
            <NotificationsPopUp
              open={openNotifications}
              data={notificationsData}
            />
          }
          {isNotGpsModule && context.hasDrawer() && openLeftDrawer && (
            <Drawer
              className={classes.drawer}
              variant="permanent"
              anchor="left"
              open={openLeftDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <Toolbar
                variant="dense"
                disableGutters
                className={classes.drawerHeader}
              >
                <AppTitle
                  id="app-title-close"
                  className={classes.appTitle}
                  style={{ color: menuButtonColor }}
                  action={() => setOpenLeftDrawer(false)}
                />
              </Toolbar>
              <MainMenu className={classes.mainMenu} context={context} />
            </Drawer>
          )}
          <main
            className={clsx(
              (isNotGpsModule && isNotEsmModule) ? classes.content : classes.gpsContent,
              {
                [classes.contentShift]: true/*open*/,
              }
            )}
            style={
              // isNotGpsModule && windowSize && windowSize > 959
              //   ? { width: "calc(100% - 280px)" } : 
              { width: "100%" }
            }
          >
            {props.children}

            {isNotGpsModule ? <Footer /> : undefined}
          </main>
        </div>
      )}
    </AppContext.Consumer>
  );
}
